/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FileControlRequest,
  FileControlResponse,
} from '../models/index';
import {
    FileControlRequestFromJSON,
    FileControlRequestToJSON,
    FileControlResponseFromJSON,
    FileControlResponseToJSON,
} from '../models/index';

export interface FileControlApiCreateFileControlRequest {
    fileControlRequest: FileControlRequest;
}

export interface FileControlApiGetFileControlByIdRequest {
    id: string;
}

export interface FileControlApiUpdateFileControlRequest {
    id: string;
    fileControlRequest: FileControlRequest;
}

/**
 * 
 */
export class FileControlApi extends runtime.BaseAPI {

    /**
     * Create file control
     */
    async createFileControlRaw(requestParameters: FileControlApiCreateFileControlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileControlResponse>> {
        if (requestParameters.fileControlRequest === null || requestParameters.fileControlRequest === undefined) {
            throw new runtime.RequiredError('fileControlRequest','Required parameter requestParameters.fileControlRequest was null or undefined when calling createFileControl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/file-control`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FileControlRequestToJSON(requestParameters.fileControlRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileControlResponseFromJSON(jsonValue));
    }

    /**
     * Create file control
     */
    async createFileControl(fileControlRequest: FileControlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileControlResponse> {
        const response = await this.createFileControlRaw({ fileControlRequest: fileControlRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Get file control by id
     */
    async getFileControlByIdRaw(requestParameters: FileControlApiGetFileControlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileControlResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getFileControlById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/file-control/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileControlResponseFromJSON(jsonValue));
    }

    /**
     * Get file control by id
     */
    async getFileControlById(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileControlResponse> {
        const response = await this.getFileControlByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Get filecontrols
     */
    async getFileControlsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileControlResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/file-control/file-controls`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileControlResponseFromJSON));
    }

    /**
     * Get filecontrols
     */
    async getFileControls(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileControlResponse>> {
        const response = await this.getFileControlsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update file control
     */
    async updateFileControlRaw(requestParameters: FileControlApiUpdateFileControlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileControlResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateFileControl.');
        }

        if (requestParameters.fileControlRequest === null || requestParameters.fileControlRequest === undefined) {
            throw new runtime.RequiredError('fileControlRequest','Required parameter requestParameters.fileControlRequest was null or undefined when calling updateFileControl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/file-control/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FileControlRequestToJSON(requestParameters.fileControlRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileControlResponseFromJSON(jsonValue));
    }

    /**
     * Update file control
     */
    async updateFileControl(id: string, fileControlRequest: FileControlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileControlResponse> {
        const response = await this.updateFileControlRaw({ id: id, fileControlRequest: fileControlRequest }, initOverrides);
        return await response.value();
    }

}
