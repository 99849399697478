/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ControlRequest,
  ControlResponse,
} from '../models/index';
import {
    ControlRequestFromJSON,
    ControlRequestToJSON,
    ControlResponseFromJSON,
    ControlResponseToJSON,
} from '../models/index';

export interface ControlApiCreateControlRequest {
    controlRequest: ControlRequest;
}

export interface ControlApiGetControlByIdRequest {
    id: string;
}

export interface ControlApiUpdateControlRequest {
    id: string;
    controlRequest: ControlRequest;
}

/**
 * 
 */
export class ControlApi extends runtime.BaseAPI {

    /**
     * Create control
     */
    async createControlRaw(requestParameters: ControlApiCreateControlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlResponse>> {
        if (requestParameters.controlRequest === null || requestParameters.controlRequest === undefined) {
            throw new runtime.RequiredError('controlRequest','Required parameter requestParameters.controlRequest was null or undefined when calling createControl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/control`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlRequestToJSON(requestParameters.controlRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlResponseFromJSON(jsonValue));
    }

    /**
     * Create control
     */
    async createControl(controlRequest: ControlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlResponse> {
        const response = await this.createControlRaw({ controlRequest: controlRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Get control by id
     */
    async getControlByIdRaw(requestParameters: ControlApiGetControlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getControlById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/control/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlResponseFromJSON(jsonValue));
    }

    /**
     * Get control by id
     */
    async getControlById(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlResponse> {
        const response = await this.getControlByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Get controls
     */
    async getControlsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ControlResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/control/controls`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ControlResponseFromJSON));
    }

    /**
     * Get controls
     */
    async getControls(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ControlResponse>> {
        const response = await this.getControlsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update control
     */
    async updateControlRaw(requestParameters: ControlApiUpdateControlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateControl.');
        }

        if (requestParameters.controlRequest === null || requestParameters.controlRequest === undefined) {
            throw new runtime.RequiredError('controlRequest','Required parameter requestParameters.controlRequest was null or undefined when calling updateControl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/control/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ControlRequestToJSON(requestParameters.controlRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlResponseFromJSON(jsonValue));
    }

    /**
     * Update control
     */
    async updateControl(id: string, controlRequest: ControlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlResponse> {
        const response = await this.updateControlRaw({ id: id, controlRequest: controlRequest }, initOverrides);
        return await response.value();
    }

}
