/* tslint:disable */
/* eslint-disable */
/**
 * Catalog import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.7.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportCriteria
 */
export interface ImportCriteria {
    /**
     * 
     * @type {string}
     * @memberof ImportCriteria
     */
    searchField?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportCriteria
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportCriteria
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportCriteria
     */
    type?: ImportCriteriaTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportCriteria
     */
    status?: Array<ImportCriteriaStatusEnum>;
    /**
     * 
     * @type {number}
     * @memberof ImportCriteria
     */
    numRows?: number;
}


/**
 * @export
 */
export const ImportCriteriaTypeEnum = {
    Csv: 'csv'
} as const;
export type ImportCriteriaTypeEnum = typeof ImportCriteriaTypeEnum[keyof typeof ImportCriteriaTypeEnum];

/**
 * @export
 */
export const ImportCriteriaStatusEnum = {
    Created: 'created',
    Waiting: 'waiting',
    Importing: 'importing',
    Validating: 'validating',
    Validated: 'validated',
    Imported: 'imported',
    Failed: 'failed',
    Unvalidated: 'unvalidated'
} as const;
export type ImportCriteriaStatusEnum = typeof ImportCriteriaStatusEnum[keyof typeof ImportCriteriaStatusEnum];


/**
 * Check if a given object implements the ImportCriteria interface.
 */
export function instanceOfImportCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImportCriteriaFromJSON(json: any): ImportCriteria {
    return ImportCriteriaFromJSONTyped(json, false);
}

export function ImportCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'numRows': !exists(json, 'numRows') ? undefined : json['numRows'],
    };
}

export function ImportCriteriaToJSON(value?: ImportCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchField': value.searchField,
        'id': value.id,
        'fileName': value.fileName,
        'type': value.type,
        'status': value.status,
        'numRows': value.numRows,
    };
}

