/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.30.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItem } from './CodelistItem';
import {
    CodelistItemFromJSON,
    CodelistItemFromJSONTyped,
    CodelistItemToJSON,
} from './CodelistItem';

/**
 * 
 * @export
 * @interface AddressCodelistItem
 */
export interface AddressCodelistItem {
    /**
     * 
     * @type {string}
     * @memberof AddressCodelistItem
     */
    street: string | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof AddressCodelistItem
     */
    cityType: CodelistItem | null;
    /**
     * 
     * @type {string}
     * @memberof AddressCodelistItem
     */
    zip: string | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof AddressCodelistItem
     */
    countryType: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof AddressCodelistItem
     */
    districtType: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof AddressCodelistItem
     */
    landRegistryType: CodelistItem | null;
}

/**
 * Check if a given object implements the AddressCodelistItem interface.
 */
export function instanceOfAddressCodelistItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "cityType" in value;
    isInstance = isInstance && "zip" in value;
    isInstance = isInstance && "countryType" in value;
    isInstance = isInstance && "districtType" in value;
    isInstance = isInstance && "landRegistryType" in value;

    return isInstance;
}

export function AddressCodelistItemFromJSON(json: any): AddressCodelistItem {
    return AddressCodelistItemFromJSONTyped(json, false);
}

export function AddressCodelistItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressCodelistItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'street': json['street'],
        'cityType': CodelistItemFromJSON(json['cityType']),
        'zip': json['zip'],
        'countryType': CodelistItemFromJSON(json['countryType']),
        'districtType': CodelistItemFromJSON(json['districtType']),
        'landRegistryType': CodelistItemFromJSON(json['landRegistryType']),
    };
}

export function AddressCodelistItemToJSON(value?: AddressCodelistItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'street': value.street,
        'cityType': CodelistItemToJSON(value.cityType),
        'zip': value.zip,
        'countryType': CodelistItemToJSON(value.countryType),
        'districtType': CodelistItemToJSON(value.districtType),
        'landRegistryType': CodelistItemToJSON(value.landRegistryType),
    };
}

