/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.30.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItem } from './CodelistItem';
import {
    CodelistItemFromJSON,
    CodelistItemFromJSONTyped,
    CodelistItemToJSON,
} from './CodelistItem';
import type { EnvironmentInputForMonitoringPoint } from './EnvironmentInputForMonitoringPoint';
import {
    EnvironmentInputForMonitoringPointFromJSON,
    EnvironmentInputForMonitoringPointFromJSONTyped,
    EnvironmentInputForMonitoringPointToJSON,
} from './EnvironmentInputForMonitoringPoint';

/**
 * 
 * @export
 * @interface MonitoringPointForInstallationResult
 */
export interface MonitoringPointForInstallationResult {
    /**
     * 
     * @type {number}
     * @memberof MonitoringPointForInstallationResult
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointForInstallationResult
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointForInstallationResult
     */
    placeName: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringPointForInstallationResult
     */
    deleted: boolean;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointForInstallationResult
     */
    montPointType?: CodelistItem | null;
    /**
     * 
     * @type {EnvironmentInputForMonitoringPoint}
     * @memberof MonitoringPointForInstallationResult
     */
    environment?: EnvironmentInputForMonitoringPoint;
    /**
     * 
     * @type {number}
     * @memberof MonitoringPointForInstallationResult
     */
    historyGroupId: number;
}

/**
 * Check if a given object implements the MonitoringPointForInstallationResult interface.
 */
export function instanceOfMonitoringPointForInstallationResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "placeName" in value;
    isInstance = isInstance && "deleted" in value;
    isInstance = isInstance && "historyGroupId" in value;

    return isInstance;
}

export function MonitoringPointForInstallationResultFromJSON(json: any): MonitoringPointForInstallationResult {
    return MonitoringPointForInstallationResultFromJSONTyped(json, false);
}

export function MonitoringPointForInstallationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringPointForInstallationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'placeName': json['placeName'],
        'deleted': json['deleted'],
        'montPointType': !exists(json, 'montPointType') ? undefined : CodelistItemFromJSON(json['montPointType']),
        'environment': !exists(json, 'environment') ? undefined : EnvironmentInputForMonitoringPointFromJSON(json['environment']),
        'historyGroupId': json['historyGroupId'],
    };
}

export function MonitoringPointForInstallationResultToJSON(value?: MonitoringPointForInstallationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'placeName': value.placeName,
        'deleted': value.deleted,
        'montPointType': CodelistItemToJSON(value.montPointType),
        'environment': EnvironmentInputForMonitoringPointToJSON(value.environment),
        'historyGroupId': value.historyGroupId,
    };
}

