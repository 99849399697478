/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignImportFilePartControlsRequest,
  RemoveImportFilePartControlsRequest,
} from '../models/index';
import {
    AssignImportFilePartControlsRequestFromJSON,
    AssignImportFilePartControlsRequestToJSON,
    RemoveImportFilePartControlsRequestFromJSON,
    RemoveImportFilePartControlsRequestToJSON,
} from '../models/index';

export interface ImportFilePartControlDefinitionApiAddImportFilePartControlDefinitionsRequest {
    assignImportFilePartControlsRequest: AssignImportFilePartControlsRequest;
}

export interface ImportFilePartControlDefinitionApiGetImportFilePartControlDefinitionByImportFilePartDefinitionRequest {
    importFilePartDefinitionId: string;
}

export interface ImportFilePartControlDefinitionApiRemoveImportFilePartControlDefinitionsRequest {
    removeImportFilePartControlsRequest: RemoveImportFilePartControlsRequest;
}

/**
 * 
 */
export class ImportFilePartControlDefinitionApi extends runtime.BaseAPI {

    /**
     * Add control definitions to import part file
     */
    async addImportFilePartControlDefinitionsRaw(requestParameters: ImportFilePartControlDefinitionApiAddImportFilePartControlDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.assignImportFilePartControlsRequest === null || requestParameters.assignImportFilePartControlsRequest === undefined) {
            throw new runtime.RequiredError('assignImportFilePartControlsRequest','Required parameter requestParameters.assignImportFilePartControlsRequest was null or undefined when calling addImportFilePartControlDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-part-control-definition/add`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignImportFilePartControlsRequestToJSON(requestParameters.assignImportFilePartControlsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Add control definitions to import part file
     */
    async addImportFilePartControlDefinitions(assignImportFilePartControlsRequest: AssignImportFilePartControlsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.addImportFilePartControlDefinitionsRaw({ assignImportFilePartControlsRequest: assignImportFilePartControlsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Get import file part control definitions by import file part definition
     */
    async getImportFilePartControlDefinitionByImportFilePartDefinitionRaw(requestParameters: ImportFilePartControlDefinitionApiGetImportFilePartControlDefinitionByImportFilePartDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.importFilePartDefinitionId === null || requestParameters.importFilePartDefinitionId === undefined) {
            throw new runtime.RequiredError('importFilePartDefinitionId','Required parameter requestParameters.importFilePartDefinitionId was null or undefined when calling getImportFilePartControlDefinitionByImportFilePartDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-part-control-definition/byImportFilePartDefinition/{importFilePartDefinitionId}`.replace(`{${"importFilePartDefinitionId"}}`, encodeURIComponent(String(requestParameters.importFilePartDefinitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get import file part control definitions by import file part definition
     */
    async getImportFilePartControlDefinitionByImportFilePartDefinition(importFilePartDefinitionId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getImportFilePartControlDefinitionByImportFilePartDefinitionRaw({ importFilePartDefinitionId: importFilePartDefinitionId }, initOverrides);
        return await response.value();
    }

    /**
     * Remove control definitions from import part file
     */
    async removeImportFilePartControlDefinitionsRaw(requestParameters: ImportFilePartControlDefinitionApiRemoveImportFilePartControlDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.removeImportFilePartControlsRequest === null || requestParameters.removeImportFilePartControlsRequest === undefined) {
            throw new runtime.RequiredError('removeImportFilePartControlsRequest','Required parameter requestParameters.removeImportFilePartControlsRequest was null or undefined when calling removeImportFilePartControlDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-part-control-definition/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveImportFilePartControlsRequestToJSON(requestParameters.removeImportFilePartControlsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Remove control definitions from import part file
     */
    async removeImportFilePartControlDefinitions(removeImportFilePartControlsRequest: RemoveImportFilePartControlsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.removeImportFilePartControlDefinitionsRaw({ removeImportFilePartControlsRequest: removeImportFilePartControlsRequest }, initOverrides);
        return await response.value();
    }

}
