/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataFetchSo
 */
export interface DataFetchSo {
    /**
     * 
     * @type {number}
     * @memberof DataFetchSo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DataFetchSo
     */
    status?: DataFetchSoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof DataFetchSo
     */
    progress?: number;
    /**
     * 
     * @type {string}
     * @memberof DataFetchSo
     */
    filterName?: string;
    /**
     * 
     * @type {number}
     * @memberof DataFetchSo
     */
    filterId?: number;
    /**
     * 
     * @type {string}
     * @memberof DataFetchSo
     */
    previewName?: string;
    /**
     * 
     * @type {string}
     * @memberof DataFetchSo
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof DataFetchSo
     */
    dateCreated?: string;
}


/**
 * @export
 */
export const DataFetchSoStatusEnum = {
    None: 'NONE',
    DownloadQueued: 'DOWNLOAD_QUEUED',
    DownloadInProgress: 'DOWNLOAD_IN_PROGRESS',
    FillingGaps: 'FILLING_GAPS',
    DownloadFailed: 'DOWNLOAD_FAILED',
    Downloaded: 'DOWNLOADED',
    Modified: 'MODIFIED',
    StandaloneValidationQueued: 'STANDALONE_VALIDATION_QUEUED',
    StandaloneValidationInProgress: 'STANDALONE_VALIDATION_IN_PROGRESS',
    StandaloneValidationFailed: 'STANDALONE_VALIDATION_FAILED',
    StandaloneValidated: 'STANDALONE_VALIDATED',
    UpToDateCheckQueued: 'UP_TO_DATE_CHECK_QUEUED',
    UpToDateCheckInProgress: 'UP_TO_DATE_CHECK_IN_PROGRESS',
    UpToDateCheckFailed: 'UP_TO_DATE_CHECK_FAILED',
    ChangedInDr: 'CHANGED_IN_DR',
    SavingToDrQueued: 'SAVING_TO_DR_QUEUED',
    SavingToDrInProgress: 'SAVING_TO_DR_IN_PROGRESS',
    SavingToDrFailed: 'SAVING_TO_DR_FAILED',
    SavedToDr: 'SAVED_TO_DR',
    DeleteQueued: 'DELETE_QUEUED',
    DeleteFailed: 'DELETE_FAILED',
    DeleteInProgress: 'DELETE_IN_PROGRESS'
} as const;
export type DataFetchSoStatusEnum = typeof DataFetchSoStatusEnum[keyof typeof DataFetchSoStatusEnum];


/**
 * Check if a given object implements the DataFetchSo interface.
 */
export function instanceOfDataFetchSo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataFetchSoFromJSON(json: any): DataFetchSo {
    return DataFetchSoFromJSONTyped(json, false);
}

export function DataFetchSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataFetchSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'progress': !exists(json, 'progress') ? undefined : json['progress'],
        'filterName': !exists(json, 'filterName') ? undefined : json['filterName'],
        'filterId': !exists(json, 'filterId') ? undefined : json['filterId'],
        'previewName': !exists(json, 'previewName') ? undefined : json['previewName'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'dateCreated': !exists(json, 'dateCreated') ? undefined : json['dateCreated'],
    };
}

export function DataFetchSoToJSON(value?: DataFetchSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': value.status,
        'progress': value.progress,
        'filterName': value.filterName,
        'filterId': value.filterId,
        'previewName': value.previewName,
        'reference': value.reference,
        'dateCreated': value.dateCreated,
    };
}

