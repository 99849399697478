/* tslint:disable */
/* eslint-disable */
/**
 * Catalog import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.7.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitoringCodelistItemInputRequired } from './MonitoringCodelistItemInputRequired';
import {
    MonitoringCodelistItemInputRequiredFromJSON,
    MonitoringCodelistItemInputRequiredFromJSONTyped,
    MonitoringCodelistItemInputRequiredToJSON,
} from './MonitoringCodelistItemInputRequired';

/**
 * 
 * @export
 * @interface Monitoring
 */
export interface Monitoring {
    /**
     * 
     * @type {Array<MonitoringCodelistItemInputRequired>}
     * @memberof Monitoring
     */
    monitoring?: Array<MonitoringCodelistItemInputRequired>;
}

/**
 * Check if a given object implements the Monitoring interface.
 */
export function instanceOfMonitoring(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitoringFromJSON(json: any): Monitoring {
    return MonitoringFromJSONTyped(json, false);
}

export function MonitoringFromJSONTyped(json: any, ignoreDiscriminator: boolean): Monitoring {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoring': !exists(json, 'monitoring') ? undefined : ((json['monitoring'] as Array<any>).map(MonitoringCodelistItemInputRequiredFromJSON)),
    };
}

export function MonitoringToJSON(value?: Monitoring | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitoring': value.monitoring === undefined ? undefined : ((value.monitoring as Array<any>).map(MonitoringCodelistItemInputRequiredToJSON)),
    };
}

