/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.30.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ThresholdCriteria
 */
export interface ThresholdCriteria {
    /**
     * 
     * @type {string}
     * @memberof ThresholdCriteria
     */
    searchField?: string;
    /**
     * 
     * @type {number}
     * @memberof ThresholdCriteria
     */
    determinantType?: number;
    /**
     * 
     * @type {number}
     * @memberof ThresholdCriteria
     */
    value?: number;
    /**
     * 
     * @type {number}
     * @memberof ThresholdCriteria
     */
    valueType?: number;
    /**
     * 
     * @type {number}
     * @memberof ThresholdCriteria
     */
    cycleType?: number;
    /**
     * 
     * @type {number}
     * @memberof ThresholdCriteria
     */
    waterShapeId?: number;
}

/**
 * Check if a given object implements the ThresholdCriteria interface.
 */
export function instanceOfThresholdCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ThresholdCriteriaFromJSON(json: any): ThresholdCriteria {
    return ThresholdCriteriaFromJSONTyped(json, false);
}

export function ThresholdCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThresholdCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'determinantType': !exists(json, 'determinantType') ? undefined : json['determinantType'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'valueType': !exists(json, 'valueType') ? undefined : json['valueType'],
        'cycleType': !exists(json, 'cycleType') ? undefined : json['cycleType'],
        'waterShapeId': !exists(json, 'waterShapeId') ? undefined : json['waterShapeId'],
    };
}

export function ThresholdCriteriaToJSON(value?: ThresholdCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchField': value.searchField,
        'determinantType': value.determinantType,
        'value': value.value,
        'valueType': value.valueType,
        'cycleType': value.cycleType,
        'waterShapeId': value.waterShapeId,
    };
}

