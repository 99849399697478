import { useEffect, useState } from 'react';
import {
    ColumnDef,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    Table,
    useReactTable,
    getPaginationRowModel,
    InitialTableState,
    TableState,
    ColumnDefTemplate,
    CellContext,
    getExpandedRowModel,
    ExpandedState
} from '@tanstack/react-table';
import { RowSelectionState } from '@tanstack/table-core';
import { get } from 'lodash';
import { TableDensity } from '../store/preferences-context';
import { Paths } from '../utils/commonTypes';

type BaseTableProps<T extends Object> = {
    /** Definícia stĺpcov tabuľky */
    columns: ColumnDef<T>[];
    /** Dáta tabuľky */
    data?: T[];
    /** Iniciálny state tabuľky */
    initialState?: InitialTableState;
    /** deletedKey definujeme v prípade že dáta v tabuľke môžu byť označené ako vymazané a chceme aby ich tabuľka vizuálne odlíšila */
    deletedKey?: Paths<T>;
    /** displayedKey definujeme v prípade že dáta v tabuľke môžu byť označené ako zobrazené a ak nie sú zobrazené chceme aby ich tabuľka vizuálne odlíšila */
    displayedKey?: Paths<T>;
    /** Metóda na aktualizíciu hodnoty v riadku */
    updateRow?: (attributeId: string, data: T) => Promise<void>;
    /** Alternatívna komponenta určujúca spôsob renderovania buniek */
    cellComponent?: ColumnDefTemplate<CellContext<T, unknown>>;
    /** Hustota zobrazenia tabuľky */
    density?: TableDensity;
};

type PaginatedTableProps<T extends Object> = {
    /** Metóda na dotiahnutie podriadkov */
    fetchSubRows?: never;
    /** rowKey definujeme v prípade že používame napr. rowSelection, čím určíme čo má tabuľka vrátiť ako identifikátor riadka */
    rowKey?: Paths<T>;
} & BaseTableProps<T>;

type PaginatedTablePropsWithSubrows<T extends Object> = {
    /** Metóda na dotiahnutie podriadkov */
    fetchSubRows: (identifier: any, row: T, signal: AbortSignal) => Promise<T[]>;
    /** rowKey definujeme v prípade že používame napr. rowSelection, čím určíme čo má tabuľka vrátiť ako identifikátor riadka */
    rowKey: Paths<T>;
} & BaseTableProps<T>;

type MemoryTable<T extends Object> = {
    table: Table<T>;
    loadingSubrows: boolean;
    state: TableState;
};

const useMemoryTable = <T extends object>({
    columns,
    data: allData,
    fetchSubRows,
    initialState,
    rowKey,
    deletedKey,
    displayedKey,
    updateRow,
    cellComponent,
    density = 'normal'
}: PaginatedTableProps<T> | PaginatedTablePropsWithSubrows<T>): MemoryTable<T> => {
    const [sorting, setSorting] = useState<SortingState>(initialState?.sorting ?? []);
    const [loadingSubrows, setLoadingSubrows] = useState(false);
    const [data, setData] = useState<T[]>([]);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [expanded, setExpanded] = useState<ExpandedState>(initialState?.expanded ?? {});

    const table = useReactTable({
        columns,
        defaultColumn: cellComponent
            ? {
                  cell: cellComponent
              }
            : undefined,
        data,
        meta: {
            totalElements: data?.length ?? 0,
            loaded: !!data,
            updateRow,
            deletedKey,
            displayedKey,
            rowKey,
            density
        },
        state: {
            sorting,
            rowSelection,
            expanded
        },
        onExpandedChange: setExpanded,
        getSubRows: (row) => (row as T & { subRows: T[] | undefined }).subRows,
        initialState: initialState,
        onSortingChange: setSorting,
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getRowId: rowKey
            ? (row) => {
                  const value = get(row, rowKey);
                  if (value) {
                      return `${value}`;
                  }
                  return '';
              }
            : undefined,
        enableRowSelection: (row) => {
            if (deletedKey && !!get(row.original, deletedKey)) {
                return false;
            }
            return true;
        }
    });

    useEffect(() => {
        const controller = new AbortController();
        setLoadingSubrows(true);
        table.resetRowSelection();
        (async () => {
            const identifiers = Object.keys(expanded);
            if (rowKey && fetchSubRows && identifiers.length > 0) {
                if (!controller.signal.aborted) {
                    const promises: Array<Promise<{ data: T[]; identifier: string }>> = [];
                    for (const identifier of identifiers) {
                        const row = allData?.find((row) => `${get(row, rowKey)}` === identifier);
                        if (row) {
                            promises.push(
                                fetchSubRows(identifier, row, controller.signal)
                                    .catch((e) => {
                                        if (e.name !== 'AbortError') {
                                            throw e;
                                        }
                                        return [] as T[];
                                    })
                                    .then((data) => {
                                        return {
                                            identifier,
                                            data
                                        };
                                    })
                            );
                        }
                    }
                    const results = await Promise.all(promises);

                    if (!controller.signal.aborted) {
                        const extendedContent = (allData ?? []).map((row) => {
                            return {
                                ...row,
                                subRows: results.find((d) => d.identifier === `${get(row, rowKey)}`)?.data
                            };
                        });
                        setData(extendedContent);
                    }
                }
            } else {
                if (!controller.signal.aborted) {
                    setData(allData ?? []);
                }
            }
        })().finally(() => {
            setLoadingSubrows(false);
        });

        return () => {
            controller.abort();
        };
    }, [expanded, fetchSubRows, rowKey, allData, table]);

    return {
        table,
        loadingSubrows,
        state: table.getState()
    };
};

export default useMemoryTable;
