/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NoteSo } from './NoteSo';
import {
    NoteSoFromJSON,
    NoteSoFromJSONTyped,
    NoteSoToJSON,
} from './NoteSo';
import type { TechnicalAttribute } from './TechnicalAttribute';
import {
    TechnicalAttributeFromJSON,
    TechnicalAttributeFromJSONTyped,
    TechnicalAttributeToJSON,
} from './TechnicalAttribute';
import type { ValidationResultSo } from './ValidationResultSo';
import {
    ValidationResultSoFromJSON,
    ValidationResultSoFromJSONTyped,
    ValidationResultSoToJSON,
} from './ValidationResultSo';

/**
 * 
 * @export
 * @interface RowAttributeSo
 */
export interface RowAttributeSo {
    /**
     * 
     * @type {string}
     * @memberof RowAttributeSo
     */
    viewColumnReference: string;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeSo
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeSo
     */
    originalValue: string;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeSo
     */
    changedOriginalValue: string;
    /**
     * 
     * @type {TechnicalAttribute}
     * @memberof RowAttributeSo
     */
    technicalAttribute?: TechnicalAttribute;
    /**
     * 
     * @type {Array<ValidationResultSo>}
     * @memberof RowAttributeSo
     */
    validationResults?: Array<ValidationResultSo>;
    /**
     * 
     * @type {NoteSo}
     * @memberof RowAttributeSo
     */
    noteSo?: NoteSo;
    /**
     * 
     * @type {boolean}
     * @memberof RowAttributeSo
     */
    wasCommented?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RowAttributeSo
     */
    localFile?: boolean;
}

/**
 * Check if a given object implements the RowAttributeSo interface.
 */
export function instanceOfRowAttributeSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "viewColumnReference" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "originalValue" in value;
    isInstance = isInstance && "changedOriginalValue" in value;

    return isInstance;
}

export function RowAttributeSoFromJSON(json: any): RowAttributeSo {
    return RowAttributeSoFromJSONTyped(json, false);
}

export function RowAttributeSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RowAttributeSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'viewColumnReference': json['viewColumnReference'],
        'value': json['value'],
        'originalValue': json['originalValue'],
        'changedOriginalValue': json['changedOriginalValue'],
        'technicalAttribute': !exists(json, 'technicalAttribute') ? undefined : TechnicalAttributeFromJSON(json['technicalAttribute']),
        'validationResults': !exists(json, 'validationResults') ? undefined : ((json['validationResults'] as Array<any>).map(ValidationResultSoFromJSON)),
        'noteSo': !exists(json, 'noteSo') ? undefined : NoteSoFromJSON(json['noteSo']),
        'wasCommented': !exists(json, 'wasCommented') ? undefined : json['wasCommented'],
        'localFile': !exists(json, 'localFile') ? undefined : json['localFile'],
    };
}

export function RowAttributeSoToJSON(value?: RowAttributeSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'viewColumnReference': value.viewColumnReference,
        'value': value.value,
        'originalValue': value.originalValue,
        'changedOriginalValue': value.changedOriginalValue,
        'technicalAttribute': TechnicalAttributeToJSON(value.technicalAttribute),
        'validationResults': value.validationResults === undefined ? undefined : ((value.validationResults as Array<any>).map(ValidationResultSoToJSON)),
        'noteSo': NoteSoToJSON(value.noteSo),
        'wasCommented': value.wasCommented,
        'localFile': value.localFile,
    };
}

