/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ErrorSort
 */
export interface ErrorSort {
    /**
     * 
     * @type {string}
     * @memberof ErrorSort
     */
    column?: ErrorSortColumnEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ErrorSort
     */
    ascending?: boolean;
}


/**
 * @export
 */
export const ErrorSortColumnEnum = {
    PreviewDataId: 'PREVIEW_DATA_ID',
    Date: 'DATE',
    Time: 'TIME',
    ViewColumnReference: 'VIEW_COLUMN_REFERENCE',
    ControlCriticalityType: 'CONTROL_CRITICALITY_TYPE',
    MonitoringPointName: 'MONITORING_POINT_NAME'
} as const;
export type ErrorSortColumnEnum = typeof ErrorSortColumnEnum[keyof typeof ErrorSortColumnEnum];


/**
 * Check if a given object implements the ErrorSort interface.
 */
export function instanceOfErrorSort(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ErrorSortFromJSON(json: any): ErrorSort {
    return ErrorSortFromJSONTyped(json, false);
}

export function ErrorSortFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorSort {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'column': !exists(json, 'column') ? undefined : json['column'],
        'ascending': !exists(json, 'ascending') ? undefined : json['ascending'],
    };
}

export function ErrorSortToJSON(value?: ErrorSort | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'column': value.column,
        'ascending': value.ascending,
    };
}

