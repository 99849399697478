/* tslint:disable */
/* eslint-disable */
/**
 * Catalog import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.7.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitoringCodelistItemInputRequired } from './MonitoringCodelistItemInputRequired';
import {
    MonitoringCodelistItemInputRequiredFromJSON,
    MonitoringCodelistItemInputRequiredFromJSONTyped,
    MonitoringCodelistItemInputRequiredToJSON,
} from './MonitoringCodelistItemInputRequired';
import type { PointCategoryCodelistItemInputRequired } from './PointCategoryCodelistItemInputRequired';
import {
    PointCategoryCodelistItemInputRequiredFromJSON,
    PointCategoryCodelistItemInputRequiredFromJSONTyped,
    PointCategoryCodelistItemInputRequiredToJSON,
} from './PointCategoryCodelistItemInputRequired';

/**
 * 
 * @export
 * @interface ImportResult
 */
export interface ImportResult {
    /**
     * 
     * @type {string}
     * @memberof ImportResult
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportResult
     */
    type?: ImportResultTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ImportResult
     */
    status?: ImportResultStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ImportResult
     */
    numRows?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportResult
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ImportResult
     */
    montPointType?: number;
    /**
     * 
     * @type {Array<PointCategoryCodelistItemInputRequired>}
     * @memberof ImportResult
     */
    pointCategories?: Array<PointCategoryCodelistItemInputRequired>;
    /**
     * 
     * @type {Array<MonitoringCodelistItemInputRequired>}
     * @memberof ImportResult
     */
    monitoring?: Array<MonitoringCodelistItemInputRequired>;
    /**
     * 
     * @type {number}
     * @memberof ImportResult
     */
    validationErrorCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ImportResult
     */
    progress?: number | null;
}


/**
 * @export
 */
export const ImportResultTypeEnum = {
    Csv: 'csv'
} as const;
export type ImportResultTypeEnum = typeof ImportResultTypeEnum[keyof typeof ImportResultTypeEnum];

/**
 * @export
 */
export const ImportResultStatusEnum = {
    Created: 'created',
    Waiting: 'waiting',
    Importing: 'importing',
    Validating: 'validating',
    Validated: 'validated',
    Imported: 'imported',
    Failed: 'failed',
    Unvalidated: 'unvalidated'
} as const;
export type ImportResultStatusEnum = typeof ImportResultStatusEnum[keyof typeof ImportResultStatusEnum];


/**
 * Check if a given object implements the ImportResult interface.
 */
export function instanceOfImportResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ImportResultFromJSON(json: any): ImportResult {
    return ImportResultFromJSONTyped(json, false);
}

export function ImportResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'numRows': !exists(json, 'numRows') ? undefined : json['numRows'],
        'id': json['id'],
        'montPointType': !exists(json, 'montPointType') ? undefined : json['montPointType'],
        'pointCategories': !exists(json, 'pointCategories') ? undefined : ((json['pointCategories'] as Array<any>).map(PointCategoryCodelistItemInputRequiredFromJSON)),
        'monitoring': !exists(json, 'monitoring') ? undefined : ((json['monitoring'] as Array<any>).map(MonitoringCodelistItemInputRequiredFromJSON)),
        'validationErrorCount': !exists(json, 'validationErrorCount') ? undefined : json['validationErrorCount'],
        'progress': !exists(json, 'progress') ? undefined : json['progress'],
    };
}

export function ImportResultToJSON(value?: ImportResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileName': value.fileName,
        'type': value.type,
        'status': value.status,
        'numRows': value.numRows,
        'id': value.id,
        'montPointType': value.montPointType,
        'pointCategories': value.pointCategories === undefined ? undefined : ((value.pointCategories as Array<any>).map(PointCategoryCodelistItemInputRequiredToJSON)),
        'monitoring': value.monitoring === undefined ? undefined : ((value.monitoring as Array<any>).map(MonitoringCodelistItemInputRequiredToJSON)),
        'validationErrorCount': value.validationErrorCount,
        'progress': value.progress,
    };
}

