import { get } from 'lodash';
import {
    ColumnHistoryActionEnum,
    ColumnHistorySourceEnum,
    ErrorPageRequestSoControlCriticalityTypeEnum,
    FetchSearchSoStatusEnum
} from '../openapi/overview';
import { AccountSoMessageTypeEnum, MessageSoMessageTypeEnum } from '../openapi/notificator';
import {
    OperatorCriteriaStatusEnum,
    UpdateOperatorStatusStatusEnum,
    OperatorResultStatusEnum,
    OperatorCriteriaNotificationDutyEnum,
    OperatorCriteriaEvaluatedEnum
} from '../openapi/catalog';
import {
    DocumentCriteriaRequestImportStatusesEnum,
    DocumentResponseStatusEnum,
    CodelistItemResponseStatusEnum
} from '../openapi/codelist-import';
import {
    DocumentCriteriaRequestImportStatusEnum,
    DocumentResponseImportStatusEnum,
    RunControlsControlCriticalityDecisionTypeEnum,
    ValidationReportResponseControlCriticalityTypeEnum,
    ValidationResultResponseControlCriticalityTypeEnum
} from '../openapi/data-import';
import { EncodingEnum, FileTypeEnum, FixedCodeitemAttributesEnum, SeparatorEnum } from './enums';
import {
    ImportCriteriaStatusEnum,
    ImportDataCriteriaValidationEnum,
    ImportDataResultStatusEnum,
    ImportDataResultValidationEnum,
    ImportResultStatusEnum,
    ImportSimpleResultStatusEnum
} from '../openapi/catalog-import';
import {
    ImportFileCriteriaStatusEnum,
    ImportFileStatusEnum,
    MethodicCriteriaStatusEnum,
    MethodicStatusEnum
} from '../openapi/methodic-import';

/** Pozor pri zmene nazvov, ak by kolidoval nazov fieldu medzi roznymi objektami bude sa to musiet cele zrefaktorovat,
 *  zatial sa pouziva v historii a pri erroroch v useErrorMiddleware
 **/

const BUNDLES = {
    codelist: {
        Codelist: {
            childObject: {
                CodelistAttribute: 'Atribút',
                en: 'Lokalizácia - Angličtina',
                sk: 'Lokalizácia - Slovenčina'
            },
            Codelist: {
                acronym: 'Akronym',
                code: 'Kód',
                deleted: 'Odstránenie číselníka',
                effectiveFrom: 'Začiatok účinnosti',
                effectiveTo: 'Koniec účinnosti',
                source: 'Zdroj',
                status: 'Stav',
                validFrom: 'Dátum platnosti'
            },
            CodelistAttribute: {
                codelistParent: 'Nadradený číselník',
                dataType: 'Dátový typ',
                deleted: 'Odstránenie',
                description: 'Popis',
                key: 'Kľúč',
                name: 'Názov',
                new: 'Pridanie',
                primaryKey: 'Primárny kľúč',
                required: 'Povinná položka',
                unique: 'Jedinečná položka'
            },
            CodelistLocalization: {
                deleted: 'Odstránenie',
                manager: 'Manažér',
                name: 'Názov',
                new: 'Pridanie',
                note: 'Poznámka'
            }
        },
        CodelistItem: {
            childObject: {
                CodelistAttribute: 'Atribút',
                en: 'Lokalizácia - Angličtina',
                sk: 'Lokalizácia - Slovenčina'
            },
            Attachment: {
                deleted: 'Odstránenie prílohy',
                new: 'Pridanie prílohy'
            },
            CodelistItem: {
                acronym: 'Akronym',
                code: 'Kód',
                deleted: 'Odstránenie číselníkovej položky',
                effectiveFrom: 'Začiatok účinnosti',
                effectiveTo: 'Koniec účinnosti',
                restore: 'Obnovenie číselníkovej položky',
                status: 'Stav',
                validFrom: 'Dátum platnosti'
            },
            CodelistItemAttribute: {
                deleted: 'Odstránenie',
                new: 'Pridanie',
                value: 'Hodnota'
            },
            CodelistItemLocalization: {
                abbreviatedName: 'Skratka názvu',
                additionalContent: 'Dodatočný obsah',
                deleted: 'Odstránenie',
                name: 'Názov',
                new: 'Pridanie',
                note: 'Poznámka',
                shortenedName: 'Skrátený názov'
            }
        }
    },
    catalog: {
        Environment: {
            Environment: {
                active: 'Aktivovanosť',
                basinType: 'Subpovodie',
                code: 'Kód',
                deleted: 'Odstránenie vodného toku',
                effectiveFrom: 'Začiatok účinnosti',
                effectiveTo: 'Koniec účinnosti',
                environmentFormType: 'Tvar toku',
                environmentType: 'Typ toku',
                hierarchicalLevel: 'Hierarchická úroveň toku',
                hydrologicalNumber: 'Hydrologické číslo',
                internationalCode: 'Medzinárodný kód',
                internationalName: 'Medzinárodný názov',
                length: 'Dĺžka toku',
                level: 'Hydrografické čísla - Úroveň',
                managerCode: 'Kód správcu toku',
                mapNumber: 'Mapové číslo',
                name: 'Názov',
                nameForGis: 'Názov pre GIS',
                nameForReport: 'Názov pre rpt',
                note: 'Poznámka',
                positionType: 'Pozícia toku voči hlavnému toku',
                restore: 'Obnovenie vodného toku',
                // version: 'Verzia', // neposiela sa
                vhpid: 'Identifikátor toku vhip'
            },
            Level: {
                level1: 'Hydrografické číslo - Úroveň 1',
                level2: 'Hydrografické číslo - Úroveň 2',
                level3: 'Hydrografické číslo - Úroveň 3',
                level4: 'Hydrografické číslo - Úroveň 4',
                level5: 'Hydrografické číslo - Úroveň 5',
                level6: 'Hydrografické číslo - Úroveň 6',
                level7: 'Hydrografické číslo - Úroveň 7',
                level8: 'Hydrografické číslo - Úroveň 8',
                level9: 'Hydrografické číslo - Úroveň 9',
                level10: 'Hydrografické číslo - Úroveň 10',
                level11: 'Hydrografické číslo - Úroveň 11',
                level12: 'Hydrografické číslo - Úroveň 12',
                level13: 'Hydrografické číslo - Úroveň 13',
                level14: 'Hydrografické číslo - Úroveň 14',
                level15: 'Hydrografické číslo - Úroveň 15'
            }
        },
        WaterShape: {
            WaterShape: {
                basinType: 'Subpovodie',
                code: 'Kód',
                // cycleType: 'Cyklus', // neposiela sa
                deleted: 'Odstránenie vodného útvaru',
                effectiveFrom: 'Začiatok účinnosti',
                effectiveTo: 'Koniec účinnosti',
                flowLength: 'Dĺžka',
                jtskXEnd: 'Koniec VÚ - súradnica X',
                jtskXStart: 'Začiatok VÚ - súradnica X',
                jtskYEnd: 'Koniec VÚ - súradnica Y',
                jtskYStart: 'Začiatok VÚ - súradnica Y',
                name: 'Názov',
                restore: 'Obnovenie vodného útvaru',
                riverKmClosest: 'Rkm do',
                riverKmFurthest: 'Rkm od',
                // statusType: 'Status', // neposiela sa
                // version: 'Verzia', // neposiela sa
                wbCategoryType: 'Kategória',
                wbCharacterType: 'Druh (charakter)',
                wbGroupType: 'Skupina',
                wbType: 'Typ'
            },
            Threshold: {
                new: 'Pridanie pozaďovej hodnoty',
                deleted: 'Odstránenie pozaďovej hodnoty',
                determinantType: 'Ukazovateľ',
                valueType: 'Typ hodnoty',
                value: 'Pozaďová hodnota'
            }
        },
        Operator: {
            Address: {
                cityType: 'Mesto',
                countryType: 'Krajina',
                districtType: 'Okres',
                landRegistryType: 'Katastrálne územie',
                street: 'Ulica',
                zip: 'PSČ'
            },
            ContactPerson: {
                deleted: 'Odstránenie kontaktnej osoby',
                email: 'E-mail',
                fax: 'Fax',
                name: 'Meno',
                new: 'Pridanie kontaktnej osoby',
                phone: 'Telefónne číslo',
                surname: 'Priezvisko'
            },
            Installation: {
                deleted: 'Odstránenie prevádzky',
                new: 'Pridanie prevádzky',
                restore: 'Obnovenie prevádzky'
            },
            Operator: {
                address: 'Adresa',
                code: 'Kód',
                deleted: 'Odstránenie prevádzkovateľa',
                dic: 'DIČ',
                effectiveFrom: 'Začiatok účinnosti',
                effectiveTo: 'Koniec účinnosti',
                evaluated: 'Hodnotené',
                ico: 'IČO',
                ippc: 'IPPC',
                naceType: 'OKEČ',
                nace2Type: 'SK NACE',
                name: 'Názov',
                note: 'Poznámka',
                notificationDuty: 'Oznamovacia povinnosť',
                operatorType: 'Typ',
                restore: 'Obnovenie prevádzkovateľa',
                status: 'Stav',
                technicalCode: 'Technický kód'
            }
        },
        Installation: {
            Address: {
                cityType: 'Mesto',
                countryType: 'Krajina',
                districtType: 'Okres',
                landRegistryType: 'Katastrálne územie',
                street: 'Ulica',
                zip: 'PSČ'
            },
            ContactPerson: {
                deleted: 'Odstránenie kontaktnej osoby',
                email: 'E-mail',
                fax: 'Fax',
                name: 'Meno',
                new: 'Pridanie kontaktnej osoby',
                phone: 'Telefónne číslo',
                surname: 'Priezvisko'
            },
            GeographicData: {
                coordX: 'Súradnica X',
                coordY: 'Súradnica Y'
            },
            Installation: {
                code: 'Kód',
                deleted: 'Odstránenie prevádzky',
                installationCode: 'Kód prevádzky',
                ippc: 'IPPC',
                name: 'Názov',
                naceType: 'OKEČ',
                nace2Type: 'SK NACE',
                note: 'Poznámka',
                operator: 'Prevádzkovateľ',
                restore: 'Obnovenie prevádzky',
                status: 'Stav',
                technicalCode: 'Technický kód'
            }
        },
        MonitoringPoint: {
            Attachment: {
                deleted: 'Odstránenie prílohy',
                new: 'Pridanie prílohy'
            },
            GPS: {
                coordAcqType: 'Spôsob určenia súradníc',
                etrs89Latitude: 'ETRS89 Latitude',
                etrs89Longitude: 'ETRS89 Longitude',
                jtskX: 'JTSK súradnica X',
                jtskY: 'JTSK súradnica Y',
                latitude: 'Zemepisná šírka',
                longitude: 'Zemepisná dĺžka',
                wgs84Latitude: 'WGS84 Latitude',
                wgs84Longitude: 'WGS84 Longitude',
                coordAcqAssesment: 'Spôsob určenia súradníc pre posudky'
            },
            Monitoring: {
                dateFrom: 'Dátum od',
                dateTo: 'Dátum do',
                deleted: 'Odstránenie monitorovacej aktivity',
                measureDeviceType: 'Typ meracieho zariadenia',
                monitoringType: 'Názov monitorovacej aktivity',
                new: 'Pridanie monitorovacej aktivity',
                note: 'Poznámka'
            },
            MonitoringPoint: {
                areaType: 'Oblasť',
                assesmentBasinType: 'Povodie pre posudky',
                basinType: 'Hlavné povodie',
                code: 'Kód',
                countryType: 'Krajina',
                deleted: 'Odstránenie monitorovacieho miesta',
                districtType: 'Okres',
                effectiveFrom: 'Dátum pozorovania od',
                effectiveTo: 'Dátum pozorovania do',
                groupId: 'GroupId', // TODO zistit nazov
                hydrologicalCodeType: 'Hydrologické číslo',
                instBranchType: 'Regionálne pracovisko SHMÚ',
                landRegisterType: 'Katastrálne územie',
                locationDescription: 'Popis umiestnenia',
                mapSheetNumber: 'Číslo mapového listu',
                // montPointType: 'Typ', // neposiela sa
                municipalityForeignType: 'Obec (mimo SR)',
                municipalityType: 'Obec',
                name: 'Názov lokality',
                note: 'Poznámka',
                partialBasinType: 'Čiastkové povodie',
                placeName: 'Názov miesta',
                positionType: 'Umiestnenie voči toku',
                regionType: 'Kraj',
                restore: 'Obnovenie monitorovacieho miesta',
                statusType: 'Stav',
                subBasinType: 'Subpovodie'
                // version: 'Verzia', // neposiela sa
            },
            Other: {
                affectedType: 'Ovplyvnený/neovplyvnený KnPVV',
                altitude: 'Nadmorská výška',
                area: 'Plocha povodia v km2',
                chvoType: 'Oblasť CHVO',
                fictionPlace: 'Fiktívne miesto KnPVV',
                internationalPlaceCode: 'Medzinárodný kód miesta',
                replacePlace: 'Nahradenie miesta',
                shs: 'ŠHS'
            },
            Place: {
                childHistoryGroupId: 'Monitorovacie miesto',
                coefficient: 'Koeficient',
                deleted: 'Odstránenie monitorovacieho miesta',
                new: 'Pridanie monitorovacieho miesta'
            },
            PointCategory: {
                dateFrom: 'Dátum od',
                dateTo: 'Dátum do',
                deleted: 'Odstránenie účelu monitorovania',
                monitoringPurposeType: 'Účel monitorovania',
                new: 'Pridanie účelu monitorovania',
                note: 'Poznámka'
            },
            UndergroundWater: {
                basicGeologicalDivision: 'Základné geologické členenie',
                buildInFormType: 'Spôsob zabudovania',
                cleanMonitorPlaceYear: 'Čistenie monitorovacieho miesta PzV',
                commonCodePzV: 'Spoločný kód PzV',
                depth: 'Hĺbka vrtu',
                gwBodyType: 'Útvar PZV',
                height: 'Výška odmerného bodu',
                institutionType: 'Pracovisko SLKV',
                level: 'Úroveň PzV',
                mainHgrType: 'Hydrogeologický rajón PzV',
                partialHgrType: 'Hydrogeologický rajón VHB',
                perforationLower: 'Dolná perforácia',
                perforationUpper: 'Horná perforácia',
                sampleAreaType: 'Oblasti PzV',
                samplingDepth: 'Hĺbka odberu vzorky',
                utilization: 'Využívanosť miesta'
            },
            WaterCourse: {
                assesmentWaterFlowType: 'Vodný tok pre posudky',
                environment: 'Vodný tok',
                gisKm: 'Riečny km GIS',
                hydrographicNumber: 'Hydrografické číslo',
                vhmKm: 'Riečny km VHM',
                waterShape: 'Vodný útvar'
            },
            WaterWithdrawal: {
                balanceCode: 'Kód bilancie',
                continuityCodeBackward: 'Kód náväznosti dozadu',
                continuityCodeForward: 'Kód náväznosti dopredu',
                dopsCode: 'DOPS kód',
                guaranteedFlow: 'Zaručený prietok v m3/s (Qzar.)',
                hydrofundCode: 'Kód hydrofondu',
                installation: 'Prevádzka',
                naceType: 'OKEČ',
                nace2Type: 'SK NACE',
                orderNumber: 'Poradové číslo vodného zdroja',
                permit: 'Vodoprávne povolenie',
                q1: '1-ročný prietok v m3/s',
                q270: '270-dňový prietok v m3/s',
                q355: '355-dňový prietok v m3/s',
                qa: 'Priemerný celoročný prietok v m3/s',
                specificPurposeType: 'Špecifický účel využitia',
                waterLawDecisionNumber: 'Číslo vodoprávneho rozhodnutia',
                waterType: 'Druh zdroja'
            }
        }
    },
    monitoringPointErrors: {
        areaType: { code: 'Oblasť' },
        assesmentBasinType: { code: 'Povodie pre posudky' },
        basinType: { code: 'Hlavné povodie' },
        code: 'Kód',
        countryType: { code: 'Krajina' },
        districtType: { code: 'Okres' },
        effectiveFrom: 'Dátum pozorovania od',
        effectiveTo: 'Dátum pozorovania do',
        groupId: 'GroupId', // TODO zistit nazov
        hydrologicalCodeType: { code: 'Hydrologické číslo' },
        instBranchType: { code: 'Regionálne pracovisko SHMÚ' },
        landRegisterType: { code: 'Katastrálne územie' },
        locationDescription: 'Popis umiestnenia',
        mapSheetNumber: 'Číslo mapového listu',
        monitorings: 'Monitorovacie aktivity', // TODO overit ci to bude chodit
        montPointType: { code: 'Typ' },
        municipalityForeignType: { code: 'Obec (mimo SR)' },
        municipalityType: { code: 'Obec' },
        name: 'Názov lokality',
        note: 'Poznámka',
        partialBasinType: { code: 'Čiastkové povodie' },
        placeName: 'Názov miesta',
        pointCategories: 'Účely monitorovania', // TODO overit ci to bude chodit
        positionType: { code: 'Umiestnenie voči toku' },
        regionType: { code: 'Kraj' },
        statusType: { code: 'Stav' },
        subBasinType: { code: 'Subpovodie' },
        gps: {
            coordAcqType: {
                code: 'Spôsob určenia súradníc'
            },
            etrs89Latitude: 'ETRS89 Latitude',
            etrs89Longitude: 'ETRS89 Longitude',
            jtskX: 'JTSK súradnica X',
            jtskY: 'JTSK súradnica Y',
            latitude: 'Zemepisná šírka',
            longitude: 'Zemepisná dĺžka',
            wgs84Latitude: 'WGS84 Latitude',
            wgs84Longitude: 'WGS84 Longitude',
            coordAcqAssesment: 'Spôsob určenia súradníc pre posudky'
        },
        other: {
            affectedType: { code: 'Ovplyvnený/neovplyvnený KnPVV' },
            altitude: 'Nadmorská výška',
            area: 'Plocha povodia v km2',
            chvoType: { code: 'Oblasť CHVO' },
            fictionPlace: 'Fiktívne miesto KnPVV',
            internationalPlaceCode: 'Medzinárodný kód miesta',
            replacePlace: 'Nahradenie miesta',
            shs: 'ŠHS'
        },
        undergroundWater: {
            basicGeologicalDivision: 'Základné geologické členenie',
            buildInFormType: { code: 'Spôsob zabudovania' },
            cleanMonitorPlaceYear: 'Čistenie monitorovacieho miesta PzV',
            commonCodePzV: 'Spoločný kód PzV',
            depth: 'Hĺbka vrtu',
            gwBodyType: { code: 'Útvar PZV' },
            height: 'Výška odmerného bodu',
            institutionType: { code: 'Pracovisko SLKV' },
            level: 'Úroveň PzV',
            mainHgrType: { code: 'Hydrogeologický rajón PzV' },
            partialHgrType: { code: 'Hydrogeologický rajón VHB' },
            perforationLower: 'Dolná perforácia',
            perforationUpper: 'Horná perforácia',
            sampleAreaType: { code: 'Oblasti PzV' },
            samplingDepth: 'Hĺbka odberu vzorky',
            utilization: 'Využívanosť miesta'
        },
        waterCourse: {
            assesmentWaterFlowType: { code: 'Vodný tok pre posudky' },
            environment: { id: 'Vodný tok' },
            gisKm: 'Riečny km GIS',
            hydrographicNumber: 'Hydrografické číslo',
            vhmKm: 'Riečny km VHM',
            waterShape: { id: 'Vodný útvar' }
        },
        waterWithdrawal: {
            balanceCode: 'Kód bilancie',
            continuityCodeBackward: 'Kód náväznosti dozadu',
            continuityCodeForward: 'Kód náväznosti dopredu',
            dopsCode: 'DOPS kód',
            guaranteedFlow: 'Zaručený prietok v m3/s (Qzar.)',
            hydrofundCode: 'Kód hydrofondu',
            installation: { id: 'Prevádzka' },
            naceType: { code: 'OKEČ' },
            nace2Type: { code: 'SK NACE' },
            orderNumber: 'Poradové číslo vodného zdroja',
            permit: 'Vodoprávne povolenie',
            q1: '1-ročný prietok v m3/s',
            q270: '270-dňový prietok v m3/s',
            q355: '355-dňový prietok v m3/s',
            qa: 'Priemerný celoročný prietok v m3/s',
            specificPurposeType: { code: 'Špecifický účel využitia' },
            waterLawDecisionNumber: 'Číslo vodoprávneho rozhodnutia',
            waterType: { code: 'Druh zdroja' }
        }
    },
    overview: {
        conditionOperator: {
            LT: '< Menší',
            LE: '≤ Menší alebo rovný',
            EQ: '= Rovný',
            NE: '≠ Nerovný',
            GE: '≥ Väčší alebo rovný',
            GT: '> Väčší',
            BETWEEN: '≷ Medzi',
            IN: '∈ Zahrnutý',
            IN_COMMA_SEPARATED_ARRAY: '∈ Zahrnutý',
            IN_USER_GROUP: '∈ Zahrnutý v používateľskej skupine'
        }
    },
    domain: {
        sk: {
            shmu: {
                'odpadove-vody': {
                    kvalita: 'Odpadové vody / Kvalita'
                },
                'oznamovacia-povinnost': {
                    'vypustanie-odpadove-vody': 'Oznamovacia povinnosť / Vypúšťanie odpadovej vody',
                    'odbery-pv': 'Oznamovacia povinnosť / Odbery PV',
                    'odbery-pzv': 'Oznamovacia povinnosť / Odbery PZV'
                },
                'povrchove-vody': {
                    'kvalita-pv': 'Povrchové vody / Kvalita PV',
                    'kvantita-pv': {
                        'kvantita-pv': 'Povrchové vody / Kvantita PV / Kvantita PV',
                        'vhb-kvantita-pv': 'Povrchové vody / Kvantita PV / VHB Kvantita PV',
                        'mutnost-vody': 'Povrchové vody / Kvantita PV / Mútnosť vody',
                        hydromorfologia: 'Povrchové vody / Kvantita PV / Hydromorfológia'
                    }
                },
                'podzemne-vody': {
                    'kvalita-pzv': 'Podzemné vody / Kvalita PZV',
                    'kvantita-pzv': {
                        'kvantita-pzv': 'Podzemné vody / Kvantita PZV / Kvantita PZV',
                        'vhb-kvantita-pzv': 'Podzemné vody / Kvantita PZV / VHB Kvantita PZV'
                    }
                }
            }
        }
    }
} as const;

export const getLabel = (bundle?: string) => {
    if (bundle === undefined || bundle === null) {
        return '';
    }
    const label = get(BUNDLES, bundle);
    if (typeof label === 'string') {
        return label;
    }
    return bundle;
};

export const getLocalizedBoolean = (value: string | boolean | null = null): string => {
    const searched = `${value}`.toLowerCase();
    switch (searched) {
        case 'true':
            return 'áno';
        case 'false':
            return 'nie';
        case 'null':
            return 'nedefinované';
    }
    return '';
};

export const getLocalizedStatus = (status: OperatorCriteriaStatusEnum | UpdateOperatorStatusStatusEnum | OperatorResultStatusEnum) => {
    switch (status) {
        case 'OK':
            return 'aktívny';
        case 'BLOCK':
            return 'zablokovaný';
        case 'WAIT':
            return 'čakajúci';
        case 'DELETE':
            return 'vymazaný';
        default:
            return '';
    }
};

export const getLocalizedStateWithUndefined = (state: OperatorCriteriaNotificationDutyEnum | OperatorCriteriaEvaluatedEnum) => {
    switch (state) {
        case 'TRUE':
            return 'áno';
        case 'FALSE':
            return 'nie';
        case 'UNDEFINED':
            return 'nedefinované';
        default:
            return '';
    }
};

export const getLocalizedImportDataStatus = (state?: DocumentResponseImportStatusEnum | DocumentCriteriaRequestImportStatusEnum) => {
    switch (state) {
        case 'CHANGED':
            return 'Zmenený';
        case 'DELETED':
            return 'Vymazaný';
        case 'VALIDATED':
            return 'Skontrolovaný';
        case 'STARTED_UPLOADING_IMPORT_FILE':
            return 'Súbor sa spracováva';
        case 'UPLOAD_QUEUED':
            return '0 - Súbor je zaradený na spracovanie';
        case 'STARTED_PROCESS_PRIMARY_ATTRIBUTE':
            return '1 - Prebieha spracovanie údajov zo súboru';
        case 'FAILED_PROCESS_PRIMARY_ATTRIBUTE':
            return '1E - Spracovanie údajov zo súboru zlyhalo';
        case 'COMPLETED_PROCESS_PRIMARY_ATTRIBUTE':
            return '1OK - Údaje zo súboru úspešne spracované';
        case 'STARTED_PROCESS_AUXILIARY_ATTRIBUTE':
            return '2 - Prebieha generovanie potrebných dodatočných údajov';
        case 'COMPLETED_PROCESS_AUXILIARY_ATTRIBUTE':
            return '2OK - Potrebné dodatočne údaje úspešne vygenerované';
        case 'FAILED_PROCESS_AUXILIARY_ATTRIBUTE':
            return '2E - Generovanie potrebných dodatočných údajov zlyhalo';
        case 'STARTED_PROCESS_CALCULATION_ADDITIONAL_ATTRIBUTE':
            return '2A - Prebieha dodatočný výpočet nových atribútov';
        case 'COMPLETED_PROCESS_CALCULATION_ADDITIONAL_ATTRIBUTE':
            return '2AOK - Výpočet nových atribútov úspešne ukončený';
        case 'FAILED_PROCESS_CALCULATION_ADDITIONAL_ATTRIBUTE':
            return '2AE - Výpočet nových atribútov zlyhal';
        case 'STARTED_DATA_VALIDATION':
            return '2V - Prebieha kontrola dát';
        case 'COMPLETED_DATA_VALIDATION':
            return '2VOK - Kontrola dát bola ukončená';
        case 'FAILED_DATA_VALIDATION':
            return '2VE - Kontrola dát zlyhala';
        case 'STARTED_COLLECT_TRANSFORMATION_ERROR':
            return '3 - Generovanie validačných chýb';
        case 'COMPLETED_COLLECT_TRANSFORMATION_ERROR':
            return '3OK -Zisťovanie validačných chýb úspešne ukončené';
        case 'FAILED_COLLECT_TRANSFORMATION_ERROR':
            return '3E - Zisťovanie validačných chyb zlyhalo';
        case 'COMPLETED_UPLOADING_IMPORT_FILE':
            return '4 - Súbor je úspešne naimportovaný v module importov';
        case 'FAILED_UPLOADING_IMPORT_FILE':
            return '4E - Import súboru do modulu importov zlyhal';
        case 'STARTED_MANUAL_DATA_VALIDATION':
            return '4V - Prebieha kontrola dát';
        case 'COMPLETED_MANUAL_DATA_VALIDATION':
            return '4VOK - Kontrola dát bola ukončená';
        case 'FAILED_MANUAL_DATA_VALIDATION':
            return '4VE - Kontrola dát zlyhala';
        case 'STARTED_CONVERT_TO_DATA_REGISTRY':
            return '5 - Prebieha konverzia údajov zo súboru a dodatočných údajov na atribúty produktov v dátovom úložisku';
        case 'COMPLETED_CONVERT_TO_DATA_REGISTRY':
            return '5OK - Konverzia údajov zo súboru a dodatočných údajov na atribúty produktov v dátovom úložisku úspešne ukončená';
        case 'FAILED_CONVERT_TO_DATA_REGISTRY':
            return '5E - Konverzia údajov zo súboru a dodatočných údajov na atribúty produktov v dátovom úložisku zlyhala';
        case 'STARTED_SENDING_TO_DATA_REGISTRY':
            return '6 - Dáta sa posielajú do dátového úložiska';
        case 'COMPLETED_SENDING_TO_DATA_REGISTRY':
            return '6OK - Dáta úspešne poslané do dátového úložiska';
        case 'FAILED_SENDING_TO_DATA_REGISTRY':
            return '6E - Posielanie dát do dátového úložiska zlyhalo';
        case 'INCOMPLETED_SENDING_TO_DATA_REGISTRY':
            return '6E1 - Posielanie dat do dátového úložiska je nekompletné';
        case 'SCHEDULED_OR_RUNNING_PROCESSING_IN_DATA_REGISTRY':
            return '7SR - Dáta v Data Registry sú zaradené na spracovanie alebo sa práve spracovávajú';
        case 'PROCESSING_IN_DATA_REGISTRY_UNKNOWN_STATUS':
            return '7E1 - Nie je možné zistiť stav spracovavania dat v dátovom úložisku';
        case 'COMPLETED_PROCESSING_IN_DATA_REGISTRY':
            return '7OK - Dáta úspešne spracovane v dátovom úložisku';
        case 'FAILED_PROCESSING_IN_DATA_REGISTRY':
            return '7E - Spracovavanie dat v dátovom úložisku zlyhalo';
        default:
            return '';
    }
};

export const getLocalizedImportStatus = (
    state?: DocumentCriteriaRequestImportStatusesEnum | DocumentResponseStatusEnum | CodelistItemResponseStatusEnum
) => {
    switch (state) {
        case 'NEW':
            return 'Nový';
        case 'ACCOMPLISHED':
            return 'Dokončený';
        case 'CHANGED':
            return 'Zmenený';
        case 'DELETE':
        case 'DELETED':
            return 'Vymazaný';
        case 'RUNNING':
            return 'Bežiaci';
        case 'VALIDATED':
            return 'Skontrolovaný';
        case 'FAILED':
            return 'Zlyhal';
        case 'UNSAVED':
            return 'Neuložený';
        case 'SAVED_IN_THE_SOURCE_DB':
            return 'Uložený v zdrojovej databáze';
        default:
            return '';
    }
};

export const getLocalizedControlCriticalityDecisionType = (
    controlCriticalityDecisionType: RunControlsControlCriticalityDecisionTypeEnum
) => {
    switch (controlCriticalityDecisionType) {
        case 'ONLY_CRITICAL':
            return 'Iba kritické';
        case 'CRITICAL_AND_NON_CRITICAL':
            return 'Kritické a nekritické';
        case 'ALL':
            return 'Všetky';
    }
};

export const getLocalizedErrorPageRequestSoControlCriticalityTypeEnum = (result?: ErrorPageRequestSoControlCriticalityTypeEnum) => {
    switch (result) {
        case 'CRITICAL':
            return 'Kritická';
        case 'NON_CRITICAL':
            return 'Nekritická';
        case 'WARNING':
            return 'Varovanie';
        case 'CHANGED_IN_DR':
            return 'Hodnota zmenená v dátovom úložisku';
    }
    return null;
};

export const getLocalizedNotificationType = (type?: MessageSoMessageTypeEnum | AccountSoMessageTypeEnum) => {
    if (!type) {
        return '';
    }
    switch (type) {
        case 'CATALOG':
            return 'katalógy';
        case 'CODELIST':
            return 'číselníky';
        case 'MONITORING_POINT':
            return 'monitorovacie miesta';
        case 'INSTALLATION':
            return 'prevádzky';
        case 'OPERATOR':
            return 'prevádzkovatelia';
        case 'WATER_SHAPE':
            return 'vodné útvary';
        case 'ENVIRONMENT':
            return 'vodné toky';
        case 'DATAREGISTRY':
            return 'dátový register';
        case 'OVERVIEW_DATA_DOWNLOADED':
            return 'prevzatie dát v prehľade';
        case 'OVERVIEW_DATA_UPLOADED':
            return 'odoslanie dát v prehľade';
        case 'OVERVIEW_EXPORTED':
            return 'export z prehľadu';
        default:
            return type;
    }
};

export const getLocalizedOverviewStatus = (type?: FetchSearchSoStatusEnum) => {
    if (!type) {
        return '';
    }
    switch (type) {
        case 'NONE':
            return 'Neuvedený';
        case 'DOWNLOAD_QUEUED':
            return 'Čaká na sťahovanie';
        case 'DOWNLOAD_IN_PROGRESS':
            return 'Prebieha sťahovanie';
        case 'DOWNLOAD_FAILED':
            return 'Sťahovanie neúspešné';
        case 'DOWNLOADED':
            return 'Stiahnuté';
        case 'MODIFIED':
            return 'Upravené';
        case 'STANDALONE_VALIDATION_QUEUED':
            return 'Čaká na kontrolu dát';
        case 'STANDALONE_VALIDATION_IN_PROGRESS':
            return 'Prebieha kontrola dát';
        case 'STANDALONE_VALIDATION_FAILED':
            return 'Kontrola dát zlyhala';
        case 'STANDALONE_VALIDATED':
            return 'Kontrola dát bola ukončená';
        case 'UP_TO_DATE_CHECK_QUEUED':
            return 'Čaká na kontrolu aktuálnosti dát';
        case 'UP_TO_DATE_CHECK_IN_PROGRESS':
            return 'Prebieha kontrola aktuálnosti dát';
        case 'UP_TO_DATE_CHECK_FAILED':
            return 'Kontrola aktuálnosti dát neúspešná';
        case 'SAVING_TO_DR_QUEUED':
            return 'Čaká na odoslanie do dátového úložiska';
        case 'SAVING_TO_DR_IN_PROGRESS':
            return 'Dáta sa posielajú do dátového úložiska';
        case 'SAVING_TO_DR_FAILED':
            return 'Posielanie dát do dátového úložiska zlyhalo';
        case 'SAVED_TO_DR':
            return 'Dáta úspešne poslané do dátového úložiska';
        case 'DELETE_QUEUED':
            return 'Čaká na vymazanie dát';
        case 'DELETE_FAILED':
            return 'Vymazanie dát zlyhalo';
        case 'DELETE_IN_PROGRESS':
            return 'Prebieha vymazávanie dát';
        case 'CHANGED_IN_DR':
            return 'Zmeny v dátovom úložisku, prosím vyriešte konflikty';
        case 'FILLING_GAPS':
            return 'Prebieha dopočítavanie medzier v časovom ráde';
        default:
            return type;
    }
};

export const getLocalizedOverviewAction = (type?: ColumnHistoryActionEnum) => {
    // TODO overit preklady
    if (!type) {
        return '';
    }
    switch (type) {
        case 'CREATE':
            return 'Vytvorenie';
        case 'UPDATE':
            return 'Aktualizácia';
        case 'DELETE':
            return 'Vymazanie';
        default:
            return type;
    }
};

export const getLocalizedColumnHistorySource = (type?: ColumnHistorySourceEnum) => {
    // TODO overit preklady
    if (!type) {
        return '';
    }
    switch (type) {
        case 'IMPORT':
            return 'Import';
        case 'IMPORT_FOR_OBSERVATION':
            return 'Import od pozorovateľa';
        case 'EDIT':
            return 'Úprava';
        case 'CALCULATED':
            return 'Vypočítaná';
        default:
            return type;
    }
};

export const getLocalizedSeparator = (separator: SeparatorEnum) => {
    switch (separator) {
        case ';':
            return 'Bodkočiarka ;';
        case ',':
            return 'Čiarka ,';
        case ' ':
            return 'Medzera " "';
        case '|':
            return 'Zvislá čiara |';
        default:
            return separator;
    }
};

export const getLocalizedFileType = (fileType: FileTypeEnum) => {
    switch (fileType) {
        case 'CSV':
            return 'Comma-separated values (*.csv)';
        case 'XLS':
            return 'Excel 97-2003 (*.xls)';
        case 'XLSX':
            return 'Excel 2007-365 (*.xlsx)';
        default:
            return fileType;
    }
};

export const getLocalizedEncoding = (encoding: EncodingEnum) => {
    switch (encoding) {
        case EncodingEnum.Latin1:
            return 'Západoeurópsky (ISO-8859-1)';
        case EncodingEnum.Utf8:
            return '8-bitový Unicode Transformation Format (utf-8)';
        case EncodingEnum.Windows1250:
            return 'Stredoeurópsky (windows-1250)';
        default:
            return encoding;
    }
};

export const getLocalizedFixedCodeItemAttributes = (att: FixedCodeitemAttributesEnum) => {
    switch (att) {
        case FixedCodeitemAttributesEnum.Code:
            return 'Kód';
        case FixedCodeitemAttributesEnum.Acronym:
            return 'Akronym';
        case FixedCodeitemAttributesEnum.ShortenedName:
            return 'Skrátený názov';
        case FixedCodeitemAttributesEnum.AbbreviatedName:
            return 'Skratka názvu';
        default:
            return att;
    }
};

export const getLocalizedCatalogAndMethodicImportStatus = (
    state:
        | ImportResultStatusEnum
        | ImportCriteriaStatusEnum
        | ImportDataResultStatusEnum
        | ImportSimpleResultStatusEnum
        | MethodicStatusEnum
        | MethodicCriteriaStatusEnum
        | ImportFileCriteriaStatusEnum
        | ImportFileStatusEnum
) => {
    switch (state) {
        case 'created':
            return 'Vytvorený';
        case 'waiting':
            return 'Čakajúci';
        case 'importing':
            return 'Prebieha import';
        case 'validating':
            return 'Prebieha validácia';
        case 'validated':
            return 'Zvalidovaný';
        case 'unvalidated':
            return 'Nezvalidovaný';
        case 'imported':
            return 'Importovaný';
        case 'failed':
            return 'Zlyhaný';
        default:
            return state;
    }
};

export const getLocalizedCatalogImportDataValidation = (validation: ImportDataResultValidationEnum | ImportDataCriteriaValidationEnum) => {
    switch (validation) {
        case 'OK':
            return 'Validácia úspešná';
        case 'WRONG':
            return 'Chyba pri validácii';
        case 'WITHOUT':
            return 'Bez validácie';
        default:
            return validation;
    }
};

export const getLocalizedValidationReportResponseControlCriticalityTypeEnum = (
    validation: ValidationReportResponseControlCriticalityTypeEnum | ValidationResultResponseControlCriticalityTypeEnum
) => {
    switch (validation) {
        case 'CRITICAL':
            return 'Kritická';
        case 'NON_CRITICAL':
            return 'Nekritická';
        case 'WARNING':
            return 'Varovanie';
        default:
            return validation;
    }
};
