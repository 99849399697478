/* tslint:disable */
/* eslint-disable */
/**
 * Catalog import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.7.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemInput } from './CodelistItemInput';
import {
    CodelistItemInputFromJSON,
    CodelistItemInputFromJSONTyped,
    CodelistItemInputToJSON,
} from './CodelistItemInput';

/**
 * 
 * @export
 * @interface GPSCodelistItemInput
 */
export interface GPSCodelistItemInput {
    /**
     * 
     * @type {number}
     * @memberof GPSCodelistItemInput
     */
    etrs89Latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof GPSCodelistItemInput
     */
    etrs89Longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof GPSCodelistItemInput
     */
    jtskX?: number;
    /**
     * 
     * @type {number}
     * @memberof GPSCodelistItemInput
     */
    jtskY?: number;
    /**
     * 
     * @type {string}
     * @memberof GPSCodelistItemInput
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof GPSCodelistItemInput
     */
    longitude?: string;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof GPSCodelistItemInput
     */
    coordAcqType: CodelistItemInput | null;
    /**
     * 
     * @type {number}
     * @memberof GPSCodelistItemInput
     */
    wgs84Latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof GPSCodelistItemInput
     */
    wgs84Longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof GPSCodelistItemInput
     */
    coordAcqAssesment?: string;
}

/**
 * Check if a given object implements the GPSCodelistItemInput interface.
 */
export function instanceOfGPSCodelistItemInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "coordAcqType" in value;

    return isInstance;
}

export function GPSCodelistItemInputFromJSON(json: any): GPSCodelistItemInput {
    return GPSCodelistItemInputFromJSONTyped(json, false);
}

export function GPSCodelistItemInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): GPSCodelistItemInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'etrs89Latitude': !exists(json, 'etrs89Latitude') ? undefined : json['etrs89Latitude'],
        'etrs89Longitude': !exists(json, 'etrs89Longitude') ? undefined : json['etrs89Longitude'],
        'jtskX': !exists(json, 'jtskX') ? undefined : json['jtskX'],
        'jtskY': !exists(json, 'jtskY') ? undefined : json['jtskY'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'coordAcqType': CodelistItemInputFromJSON(json['coordAcqType']),
        'wgs84Latitude': !exists(json, 'wgs84Latitude') ? undefined : json['wgs84Latitude'],
        'wgs84Longitude': !exists(json, 'wgs84Longitude') ? undefined : json['wgs84Longitude'],
        'coordAcqAssesment': !exists(json, 'coordAcqAssesment') ? undefined : json['coordAcqAssesment'],
    };
}

export function GPSCodelistItemInputToJSON(value?: GPSCodelistItemInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'etrs89Latitude': value.etrs89Latitude,
        'etrs89Longitude': value.etrs89Longitude,
        'jtskX': value.jtskX,
        'jtskY': value.jtskY,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'coordAcqType': CodelistItemInputToJSON(value.coordAcqType),
        'wgs84Latitude': value.wgs84Latitude,
        'wgs84Longitude': value.wgs84Longitude,
        'coordAcqAssesment': value.coordAcqAssesment,
    };
}

