/* tslint:disable */
/* eslint-disable */
/**
 * Catalog import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.7.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitoringPointInput } from './MonitoringPointInput';
import {
    MonitoringPointInputFromJSON,
    MonitoringPointInputFromJSONTyped,
    MonitoringPointInputToJSON,
} from './MonitoringPointInput';

/**
 * 
 * @export
 * @interface MonitoringPointRow
 */
export interface MonitoringPointRow {
    /**
     * 
     * @type {MonitoringPointInput}
     * @memberof MonitoringPointRow
     */
    monitoringPointInput?: MonitoringPointInput;
    /**
     * 
     * @type {number}
     * @memberof MonitoringPointRow
     */
    version?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringPointRow
     */
    historyGroupId?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringPointRow
     */
    monitoringPointId?: number;
}

/**
 * Check if a given object implements the MonitoringPointRow interface.
 */
export function instanceOfMonitoringPointRow(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitoringPointRowFromJSON(json: any): MonitoringPointRow {
    return MonitoringPointRowFromJSONTyped(json, false);
}

export function MonitoringPointRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringPointRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoringPointInput': !exists(json, 'monitoringPointInput') ? undefined : MonitoringPointInputFromJSON(json['monitoringPointInput']),
        'version': !exists(json, 'version') ? undefined : json['version'],
        'historyGroupId': !exists(json, 'historyGroupId') ? undefined : json['historyGroupId'],
        'monitoringPointId': !exists(json, 'monitoringPointId') ? undefined : json['monitoringPointId'],
    };
}

export function MonitoringPointRowToJSON(value?: MonitoringPointRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitoringPointInput': MonitoringPointInputToJSON(value.monitoringPointInput),
        'version': value.version,
        'historyGroupId': value.historyGroupId,
        'monitoringPointId': value.monitoringPointId,
    };
}

