/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.30.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CodelistItemRequired
 */
export interface CodelistItemRequired {
    /**
     * 
     * @type {number}
     * @memberof CodelistItemRequired
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemRequired
     */
    acronym: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemRequired
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemRequired
     */
    effectiveFrom: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemRequired
     */
    effectiveTo: string | null;
}

/**
 * Check if a given object implements the CodelistItemRequired interface.
 */
export function instanceOfCodelistItemRequired(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "acronym" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "effectiveFrom" in value;
    isInstance = isInstance && "effectiveTo" in value;

    return isInstance;
}

export function CodelistItemRequiredFromJSON(json: any): CodelistItemRequired {
    return CodelistItemRequiredFromJSONTyped(json, false);
}

export function CodelistItemRequiredFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodelistItemRequired {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'acronym': json['acronym'],
        'name': json['name'],
        'effectiveFrom': json['effectiveFrom'],
        'effectiveTo': json['effectiveTo'],
    };
}

export function CodelistItemRequiredToJSON(value?: CodelistItemRequired | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'acronym': value.acronym,
        'name': value.name,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
    };
}

