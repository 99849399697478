import { FetchParams, Middleware, RequestContext } from '../openapi/codelist';
import { useMemo } from 'react';
import { useAuth } from 'react-oidc-context';

let token: string | null | undefined = null;

const useBearerMiddleware = (): Middleware => {
    const { user } = useAuth();
    token = user?.access_token;

    return useMemo(() => {
        return {
            pre(context: RequestContext): Promise<FetchParams | void> {
                context.init.headers = {
                    ...context.init.headers,
                    Authorization: token ? `Bearer ${token}` : ''
                };
                return Promise.resolve();
            }
        };
    }, []);
};

export default useBearerMiddleware;
