/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ErrorSort } from './ErrorSort';
import {
    ErrorSortFromJSON,
    ErrorSortFromJSONTyped,
    ErrorSortToJSON,
} from './ErrorSort';

/**
 * 
 * @export
 * @interface ErrorPageRequestSo
 */
export interface ErrorPageRequestSo {
    /**
     * 
     * @type {string}
     * @memberof ErrorPageRequestSo
     */
    viewReference: string;
    /**
     * 
     * @type {number}
     * @memberof ErrorPageRequestSo
     */
    filterId: number;
    /**
     * 
     * @type {number}
     * @memberof ErrorPageRequestSo
     */
    pageNumber: number;
    /**
     * 
     * @type {number}
     * @memberof ErrorPageRequestSo
     */
    pageSize: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorPageRequestSo
     */
    controlCriticalityType?: ErrorPageRequestSoControlCriticalityTypeEnum;
    /**
     * 
     * @type {Array<ErrorSort>}
     * @memberof ErrorPageRequestSo
     */
    sorts?: Array<ErrorSort>;
    /**
     * 
     * @type {number}
     * @memberof ErrorPageRequestSo
     */
    previewDataId?: number;
}


/**
 * @export
 */
export const ErrorPageRequestSoControlCriticalityTypeEnum = {
    Critical: 'CRITICAL',
    NonCritical: 'NON_CRITICAL',
    Warning: 'WARNING',
    ChangedInDr: 'CHANGED_IN_DR'
} as const;
export type ErrorPageRequestSoControlCriticalityTypeEnum = typeof ErrorPageRequestSoControlCriticalityTypeEnum[keyof typeof ErrorPageRequestSoControlCriticalityTypeEnum];


/**
 * Check if a given object implements the ErrorPageRequestSo interface.
 */
export function instanceOfErrorPageRequestSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "viewReference" in value;
    isInstance = isInstance && "filterId" in value;
    isInstance = isInstance && "pageNumber" in value;
    isInstance = isInstance && "pageSize" in value;

    return isInstance;
}

export function ErrorPageRequestSoFromJSON(json: any): ErrorPageRequestSo {
    return ErrorPageRequestSoFromJSONTyped(json, false);
}

export function ErrorPageRequestSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorPageRequestSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'viewReference': json['viewReference'],
        'filterId': json['filterId'],
        'pageNumber': json['pageNumber'],
        'pageSize': json['pageSize'],
        'controlCriticalityType': !exists(json, 'controlCriticalityType') ? undefined : json['controlCriticalityType'],
        'sorts': !exists(json, 'sorts') ? undefined : ((json['sorts'] as Array<any>).map(ErrorSortFromJSON)),
        'previewDataId': !exists(json, 'previewDataId') ? undefined : json['previewDataId'],
    };
}

export function ErrorPageRequestSoToJSON(value?: ErrorPageRequestSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'viewReference': value.viewReference,
        'filterId': value.filterId,
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'controlCriticalityType': value.controlCriticalityType,
        'sorts': value.sorts === undefined ? undefined : ((value.sorts as Array<any>).map(ErrorSortToJSON)),
        'previewDataId': value.previewDataId,
    };
}

