/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.30.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    fileMime?: string;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    fileSize?: number;
}

/**
 * Check if a given object implements the Attachment interface.
 */
export function instanceOfAttachment(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AttachmentFromJSON(json: any): Attachment {
    return AttachmentFromJSONTyped(json, false);
}

export function AttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fileMime': !exists(json, 'fileMime') ? undefined : json['fileMime'],
        'fileSize': !exists(json, 'fileSize') ? undefined : json['fileSize'],
    };
}

export function AttachmentToJSON(value?: Attachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'fileMime': value.fileMime,
        'fileSize': value.fileSize,
    };
}

