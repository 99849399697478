/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LocalTime } from './LocalTime';
import {
    LocalTimeFromJSON,
    LocalTimeFromJSONTyped,
    LocalTimeToJSON,
} from './LocalTime';

/**
 * 
 * @export
 * @interface ErrorPageResponseSo
 */
export interface ErrorPageResponseSo {
    /**
     * 
     * @type {number}
     * @memberof ErrorPageResponseSo
     */
    rowNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof ErrorPageResponseSo
     */
    monPointId?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorPageResponseSo
     */
    monPointName?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorPageResponseSo
     */
    date?: string;
    /**
     * 
     * @type {LocalTime}
     * @memberof ErrorPageResponseSo
     */
    time?: LocalTime;
    /**
     * 
     * @type {string}
     * @memberof ErrorPageResponseSo
     */
    viewColumnReference?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorPageResponseSo
     */
    controlCriticalityType?: ErrorPageResponseSoControlCriticalityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ErrorPageResponseSo
     */
    errorMessage?: string;
    /**
     * 
     * @type {number}
     * @memberof ErrorPageResponseSo
     */
    previewDataId?: number;
}


/**
 * @export
 */
export const ErrorPageResponseSoControlCriticalityTypeEnum = {
    Critical: 'CRITICAL',
    NonCritical: 'NON_CRITICAL',
    Warning: 'WARNING',
    ChangedInDr: 'CHANGED_IN_DR'
} as const;
export type ErrorPageResponseSoControlCriticalityTypeEnum = typeof ErrorPageResponseSoControlCriticalityTypeEnum[keyof typeof ErrorPageResponseSoControlCriticalityTypeEnum];


/**
 * Check if a given object implements the ErrorPageResponseSo interface.
 */
export function instanceOfErrorPageResponseSo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ErrorPageResponseSoFromJSON(json: any): ErrorPageResponseSo {
    return ErrorPageResponseSoFromJSONTyped(json, false);
}

export function ErrorPageResponseSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorPageResponseSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowNumber': !exists(json, 'rowNumber') ? undefined : json['rowNumber'],
        'monPointId': !exists(json, 'monPointId') ? undefined : json['monPointId'],
        'monPointName': !exists(json, 'monPointName') ? undefined : json['monPointName'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'time': !exists(json, 'time') ? undefined : LocalTimeFromJSON(json['time']),
        'viewColumnReference': !exists(json, 'viewColumnReference') ? undefined : json['viewColumnReference'],
        'controlCriticalityType': !exists(json, 'controlCriticalityType') ? undefined : json['controlCriticalityType'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'previewDataId': !exists(json, 'previewDataId') ? undefined : json['previewDataId'],
    };
}

export function ErrorPageResponseSoToJSON(value?: ErrorPageResponseSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowNumber': value.rowNumber,
        'monPointId': value.monPointId,
        'monPointName': value.monPointName,
        'date': value.date,
        'time': LocalTimeToJSON(value.time),
        'viewColumnReference': value.viewColumnReference,
        'controlCriticalityType': value.controlCriticalityType,
        'errorMessage': value.errorMessage,
        'previewDataId': value.previewDataId,
    };
}

