/* tslint:disable */
/* eslint-disable */
/**
 * Catalog import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.7.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EndpointExceptionBody } from './EndpointExceptionBody';
import {
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyFromJSONTyped,
    EndpointExceptionBodyToJSON,
} from './EndpointExceptionBody';
import type { MonitoringPointRow } from './MonitoringPointRow';
import {
    MonitoringPointRowFromJSON,
    MonitoringPointRowFromJSONTyped,
    MonitoringPointRowToJSON,
} from './MonitoringPointRow';

/**
 * 
 * @export
 * @interface ImportDataResult
 */
export interface ImportDataResult {
    /**
     * 
     * @type {string}
     * @memberof ImportDataResult
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportDataResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportDataResult
     */
    validation?: ImportDataResultValidationEnum;
    /**
     * 
     * @type {MonitoringPointRow}
     * @memberof ImportDataResult
     */
    monitoringPoint: MonitoringPointRow;
    /**
     * 
     * @type {number}
     * @memberof ImportDataResult
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ImportDataResult
     */
    status?: ImportDataResultStatusEnum;
    /**
     * 
     * @type {EndpointExceptionBody}
     * @memberof ImportDataResult
     */
    endpointExceptionBody?: EndpointExceptionBody;
    /**
     * 
     * @type {number}
     * @memberof ImportDataResult
     */
    validationErrorCount?: number | null;
}


/**
 * @export
 */
export const ImportDataResultValidationEnum = {
    Ok: 'OK',
    Wrong: 'WRONG',
    Without: 'WITHOUT'
} as const;
export type ImportDataResultValidationEnum = typeof ImportDataResultValidationEnum[keyof typeof ImportDataResultValidationEnum];

/**
 * @export
 */
export const ImportDataResultStatusEnum = {
    Created: 'created',
    Waiting: 'waiting',
    Importing: 'importing',
    Validating: 'validating',
    Validated: 'validated',
    Imported: 'imported',
    Failed: 'failed',
    Unvalidated: 'unvalidated'
} as const;
export type ImportDataResultStatusEnum = typeof ImportDataResultStatusEnum[keyof typeof ImportDataResultStatusEnum];


/**
 * Check if a given object implements the ImportDataResult interface.
 */
export function instanceOfImportDataResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "monitoringPoint" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ImportDataResultFromJSON(json: any): ImportDataResult {
    return ImportDataResultFromJSONTyped(json, false);
}

export function ImportDataResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportDataResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'validation': !exists(json, 'validation') ? undefined : json['validation'],
        'monitoringPoint': MonitoringPointRowFromJSON(json['monitoringPoint']),
        'id': json['id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'endpointExceptionBody': !exists(json, 'endpointExceptionBody') ? undefined : EndpointExceptionBodyFromJSON(json['endpointExceptionBody']),
        'validationErrorCount': !exists(json, 'validationErrorCount') ? undefined : json['validationErrorCount'],
    };
}

export function ImportDataResultToJSON(value?: ImportDataResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'validation': value.validation,
        'monitoringPoint': MonitoringPointRowToJSON(value.monitoringPoint),
        'id': value.id,
        'status': value.status,
        'endpointExceptionBody': EndpointExceptionBodyToJSON(value.endpointExceptionBody),
        'validationErrorCount': value.validationErrorCount,
    };
}

