/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SortsSo } from './SortsSo';
import {
    SortsSoFromJSON,
    SortsSoFromJSONTyped,
    SortsSoToJSON,
} from './SortsSo';

/**
 * 
 * @export
 * @interface ExportPreviewSo
 */
export interface ExportPreviewSo {
    /**
     * 
     * @type {string}
     * @memberof ExportPreviewSo
     */
    viewReference: string;
    /**
     * 
     * @type {number}
     * @memberof ExportPreviewSo
     */
    filterId: number;
    /**
     * 
     * @type {Array<SortsSo>}
     * @memberof ExportPreviewSo
     */
    sorts?: Array<SortsSo>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ExportPreviewSo
     */
    fetchFilter?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ExportPreviewSo
     */
    searchField?: string;
    /**
     * 
     * @type {number}
     * @memberof ExportPreviewSo
     */
    previewDataId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExportPreviewSo
     */
    fieldsToExport: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ExportPreviewSo
     */
    onlyChangedInDr?: boolean;
}

/**
 * Check if a given object implements the ExportPreviewSo interface.
 */
export function instanceOfExportPreviewSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "viewReference" in value;
    isInstance = isInstance && "filterId" in value;
    isInstance = isInstance && "fieldsToExport" in value;

    return isInstance;
}

export function ExportPreviewSoFromJSON(json: any): ExportPreviewSo {
    return ExportPreviewSoFromJSONTyped(json, false);
}

export function ExportPreviewSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportPreviewSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'viewReference': json['viewReference'],
        'filterId': json['filterId'],
        'sorts': !exists(json, 'sorts') ? undefined : ((json['sorts'] as Array<any>).map(SortsSoFromJSON)),
        'fetchFilter': !exists(json, 'fetchFilter') ? undefined : json['fetchFilter'],
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'previewDataId': !exists(json, 'previewDataId') ? undefined : json['previewDataId'],
        'fieldsToExport': json['fieldsToExport'],
        'onlyChangedInDr': !exists(json, 'onlyChangedInDr') ? undefined : json['onlyChangedInDr'],
    };
}

export function ExportPreviewSoToJSON(value?: ExportPreviewSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'viewReference': value.viewReference,
        'filterId': value.filterId,
        'sorts': value.sorts === undefined ? undefined : ((value.sorts as Array<any>).map(SortsSoToJSON)),
        'fetchFilter': value.fetchFilter,
        'searchField': value.searchField,
        'previewDataId': value.previewDataId,
        'fieldsToExport': value.fieldsToExport,
        'onlyChangedInDr': value.onlyChangedInDr,
    };
}

