/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ServiceErrorSo } from './ServiceErrorSo';
import {
    ServiceErrorSoFromJSON,
    ServiceErrorSoFromJSONTyped,
    ServiceErrorSoToJSON,
} from './ServiceErrorSo';

/**
 * 
 * @export
 * @interface FetchStatusSo
 */
export interface FetchStatusSo {
    /**
     * 
     * @type {number}
     * @memberof FetchStatusSo
     */
    progress?: number;
    /**
     * 
     * @type {string}
     * @memberof FetchStatusSo
     */
    status?: FetchStatusSoStatusEnum;
    /**
     * 
     * @type {ServiceErrorSo}
     * @memberof FetchStatusSo
     */
    serviceError?: ServiceErrorSo;
    /**
     * 
     * @type {number}
     * @memberof FetchStatusSo
     */
    warningCount?: number;
    /**
     * 
     * @type {number}
     * @memberof FetchStatusSo
     */
    criticalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof FetchStatusSo
     */
    nonCriticalCount?: number;
}


/**
 * @export
 */
export const FetchStatusSoStatusEnum = {
    None: 'NONE',
    DownloadQueued: 'DOWNLOAD_QUEUED',
    DownloadInProgress: 'DOWNLOAD_IN_PROGRESS',
    FillingGaps: 'FILLING_GAPS',
    DownloadFailed: 'DOWNLOAD_FAILED',
    Downloaded: 'DOWNLOADED',
    Modified: 'MODIFIED',
    StandaloneValidationQueued: 'STANDALONE_VALIDATION_QUEUED',
    StandaloneValidationInProgress: 'STANDALONE_VALIDATION_IN_PROGRESS',
    StandaloneValidationFailed: 'STANDALONE_VALIDATION_FAILED',
    StandaloneValidated: 'STANDALONE_VALIDATED',
    UpToDateCheckQueued: 'UP_TO_DATE_CHECK_QUEUED',
    UpToDateCheckInProgress: 'UP_TO_DATE_CHECK_IN_PROGRESS',
    UpToDateCheckFailed: 'UP_TO_DATE_CHECK_FAILED',
    ChangedInDr: 'CHANGED_IN_DR',
    SavingToDrQueued: 'SAVING_TO_DR_QUEUED',
    SavingToDrInProgress: 'SAVING_TO_DR_IN_PROGRESS',
    SavingToDrFailed: 'SAVING_TO_DR_FAILED',
    SavedToDr: 'SAVED_TO_DR',
    DeleteQueued: 'DELETE_QUEUED',
    DeleteFailed: 'DELETE_FAILED',
    DeleteInProgress: 'DELETE_IN_PROGRESS'
} as const;
export type FetchStatusSoStatusEnum = typeof FetchStatusSoStatusEnum[keyof typeof FetchStatusSoStatusEnum];


/**
 * Check if a given object implements the FetchStatusSo interface.
 */
export function instanceOfFetchStatusSo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FetchStatusSoFromJSON(json: any): FetchStatusSo {
    return FetchStatusSoFromJSONTyped(json, false);
}

export function FetchStatusSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchStatusSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'progress': !exists(json, 'progress') ? undefined : json['progress'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'serviceError': !exists(json, 'serviceError') ? undefined : ServiceErrorSoFromJSON(json['serviceError']),
        'warningCount': !exists(json, 'warningCount') ? undefined : json['warningCount'],
        'criticalCount': !exists(json, 'criticalCount') ? undefined : json['criticalCount'],
        'nonCriticalCount': !exists(json, 'nonCriticalCount') ? undefined : json['nonCriticalCount'],
    };
}

export function FetchStatusSoToJSON(value?: FetchStatusSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'progress': value.progress,
        'status': value.status,
        'serviceError': ServiceErrorSoToJSON(value.serviceError),
        'warningCount': value.warningCount,
        'criticalCount': value.criticalCount,
        'nonCriticalCount': value.nonCriticalCount,
    };
}

