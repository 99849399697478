/* tslint:disable */
/* eslint-disable */
/**
 * Catalog import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.7.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EndpointExceptionBody,
  ImportData,
  ImportDataCriteria,
  ImportDataResult,
  PageImportDataResult,
} from '../models/index';
import {
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    ImportDataFromJSON,
    ImportDataToJSON,
    ImportDataCriteriaFromJSON,
    ImportDataCriteriaToJSON,
    ImportDataResultFromJSON,
    ImportDataResultToJSON,
    PageImportDataResultFromJSON,
    PageImportDataResultToJSON,
} from '../models/index';

export interface ImportDataControllerApiAddMonitoringAndPointCategoriesToMonitoringPointRequest {
    fileId: number;
}

export interface ImportDataControllerApiDeleteDataByIdRequest {
    id: number;
}

export interface ImportDataControllerApiFindImportDataBySearchCriteriaRequest {
    importDataCriteria: ImportDataCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ImportDataControllerApiGetDataByIdRequest {
    id: number;
}

export interface ImportDataControllerApiImportDataToMontPointRequest {
    fileId: number;
}

export interface ImportDataControllerApiUpdateDataRequest {
    id: number;
    importData: ImportData;
}

export interface ImportDataControllerApiValidateMontPointInputByIdRequest {
    id: number;
}

/**
 * 
 */
export class ImportDataControllerApi extends runtime.BaseAPI {

    /**
     */
    async addMonitoringAndPointCategoriesToMonitoringPointRaw(requestParameters: ImportDataControllerApiAddMonitoringAndPointCategoriesToMonitoringPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling addMonitoringAndPointCategoriesToMonitoringPoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-monitoring-point/add-monitoring-point-categories/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addMonitoringAndPointCategoriesToMonitoringPoint(fileId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addMonitoringAndPointCategoriesToMonitoringPointRaw({ fileId: fileId }, initOverrides);
    }

    /**
     */
    async deleteDataByIdRaw(requestParameters: ImportDataControllerApiDeleteDataByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDataById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-monitoring-point/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteDataById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDataByIdRaw({ id: id }, initOverrides);
    }

    /**
     */
    async findImportDataBySearchCriteriaRaw(requestParameters: ImportDataControllerApiFindImportDataBySearchCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageImportDataResult>> {
        if (requestParameters.importDataCriteria === null || requestParameters.importDataCriteria === undefined) {
            throw new runtime.RequiredError('importDataCriteria','Required parameter requestParameters.importDataCriteria was null or undefined when calling findImportDataBySearchCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-monitoring-point/by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImportDataCriteriaToJSON(requestParameters.importDataCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageImportDataResultFromJSON(jsonValue));
    }

    /**
     */
    async findImportDataBySearchCriteria(importDataCriteria: ImportDataCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageImportDataResult> {
        const response = await this.findImportDataBySearchCriteriaRaw({ importDataCriteria: importDataCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDataByIdRaw(requestParameters: ImportDataControllerApiGetDataByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportDataResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDataById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-monitoring-point/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportDataResultFromJSON(jsonValue));
    }

    /**
     */
    async getDataById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportDataResult> {
        const response = await this.getDataByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async importDataToMontPointRaw(requestParameters: ImportDataControllerApiImportDataToMontPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling importDataToMontPoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-monitoring-point/import-data-to-mont-point/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async importDataToMontPoint(fileId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.importDataToMontPointRaw({ fileId: fileId }, initOverrides);
    }

    /**
     */
    async updateDataRaw(requestParameters: ImportDataControllerApiUpdateDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportDataResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateData.');
        }

        if (requestParameters.importData === null || requestParameters.importData === undefined) {
            throw new runtime.RequiredError('importData','Required parameter requestParameters.importData was null or undefined when calling updateData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-monitoring-point/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ImportDataToJSON(requestParameters.importData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportDataResultFromJSON(jsonValue));
    }

    /**
     */
    async updateData(id: number, importData: ImportData, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportDataResult> {
        const response = await this.updateDataRaw({ id: id, importData: importData }, initOverrides);
        return await response.value();
    }

    /**
     */
    async validateMontPointInputByIdRaw(requestParameters: ImportDataControllerApiValidateMontPointInputByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling validateMontPointInputById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-monitoring-point/validate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async validateMontPointInputById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.validateMontPointInputByIdRaw({ id: id }, initOverrides);
    }

}
