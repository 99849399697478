/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ControlApiRunControlsRequest {
    documentId: string;
    controlCriticalityDecisionType: RunControlsControlCriticalityDecisionTypeEnum;
}

/**
 * 
 */
export class ControlApi extends runtime.BaseAPI {

    /**
     * Run controls
     */
    async runControlsRaw(requestParameters: ControlApiRunControlsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling runControls.');
        }

        if (requestParameters.controlCriticalityDecisionType === null || requestParameters.controlCriticalityDecisionType === undefined) {
            throw new runtime.RequiredError('controlCriticalityDecisionType','Required parameter requestParameters.controlCriticalityDecisionType was null or undefined when calling runControls.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        if (requestParameters.controlCriticalityDecisionType !== undefined) {
            queryParameters['controlCriticalityDecisionType'] = requestParameters.controlCriticalityDecisionType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/control/run-controls`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Run controls
     */
    async runControls(documentId: string, controlCriticalityDecisionType: RunControlsControlCriticalityDecisionTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.runControlsRaw({ documentId: documentId, controlCriticalityDecisionType: controlCriticalityDecisionType }, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const RunControlsControlCriticalityDecisionTypeEnum = {
    OnlyCritical: 'ONLY_CRITICAL',
    CriticalAndNonCritical: 'CRITICAL_AND_NON_CRITICAL',
    All: 'ALL'
} as const;
export type RunControlsControlCriticalityDecisionTypeEnum = typeof RunControlsControlCriticalityDecisionTypeEnum[keyof typeof RunControlsControlCriticalityDecisionTypeEnum];
