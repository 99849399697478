/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidationResultSo
 */
export interface ValidationResultSo {
    /**
     * 
     * @type {string}
     * @memberof ValidationResultSo
     */
    controlCriticalityType?: ValidationResultSoControlCriticalityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ValidationResultSo
     */
    errorMessage?: string;
}


/**
 * @export
 */
export const ValidationResultSoControlCriticalityTypeEnum = {
    Critical: 'CRITICAL',
    NonCritical: 'NON_CRITICAL',
    Warning: 'WARNING',
    ChangedInDr: 'CHANGED_IN_DR'
} as const;
export type ValidationResultSoControlCriticalityTypeEnum = typeof ValidationResultSoControlCriticalityTypeEnum[keyof typeof ValidationResultSoControlCriticalityTypeEnum];


/**
 * Check if a given object implements the ValidationResultSo interface.
 */
export function instanceOfValidationResultSo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ValidationResultSoFromJSON(json: any): ValidationResultSo {
    return ValidationResultSoFromJSONTyped(json, false);
}

export function ValidationResultSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationResultSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'controlCriticalityType': !exists(json, 'controlCriticalityType') ? undefined : json['controlCriticalityType'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}

export function ValidationResultSoToJSON(value?: ValidationResultSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'controlCriticalityType': value.controlCriticalityType,
        'errorMessage': value.errorMessage,
    };
}

