/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignImportFileControlsRequest,
  RemoveImportFileControlsRequest,
} from '../models/index';
import {
    AssignImportFileControlsRequestFromJSON,
    AssignImportFileControlsRequestToJSON,
    RemoveImportFileControlsRequestFromJSON,
    RemoveImportFileControlsRequestToJSON,
} from '../models/index';

export interface ImportFileControlDefinitionApiAddImportFileControlDefinitionsRequest {
    assignImportFileControlsRequest: AssignImportFileControlsRequest;
}

export interface ImportFileControlDefinitionApiGetImportFileControlDefinitionByImportFileDefinitionRequest {
    importFileDefinitionId: string;
}

export interface ImportFileControlDefinitionApiRemoveImportFileControlDefinitionsRequest {
    removeImportFileControlsRequest: RemoveImportFileControlsRequest;
}

/**
 * 
 */
export class ImportFileControlDefinitionApi extends runtime.BaseAPI {

    /**
     * Add control definitions to import file
     */
    async addImportFileControlDefinitionsRaw(requestParameters: ImportFileControlDefinitionApiAddImportFileControlDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.assignImportFileControlsRequest === null || requestParameters.assignImportFileControlsRequest === undefined) {
            throw new runtime.RequiredError('assignImportFileControlsRequest','Required parameter requestParameters.assignImportFileControlsRequest was null or undefined when calling addImportFileControlDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-control-definition/add`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignImportFileControlsRequestToJSON(requestParameters.assignImportFileControlsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Add control definitions to import file
     */
    async addImportFileControlDefinitions(assignImportFileControlsRequest: AssignImportFileControlsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.addImportFileControlDefinitionsRaw({ assignImportFileControlsRequest: assignImportFileControlsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Get import file control definitions by import file definition
     */
    async getImportFileControlDefinitionByImportFileDefinitionRaw(requestParameters: ImportFileControlDefinitionApiGetImportFileControlDefinitionByImportFileDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.importFileDefinitionId === null || requestParameters.importFileDefinitionId === undefined) {
            throw new runtime.RequiredError('importFileDefinitionId','Required parameter requestParameters.importFileDefinitionId was null or undefined when calling getImportFileControlDefinitionByImportFileDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-control-definition/byImportFileDefinition/{importFileDefinitionId}`.replace(`{${"importFileDefinitionId"}}`, encodeURIComponent(String(requestParameters.importFileDefinitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get import file control definitions by import file definition
     */
    async getImportFileControlDefinitionByImportFileDefinition(importFileDefinitionId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getImportFileControlDefinitionByImportFileDefinitionRaw({ importFileDefinitionId: importFileDefinitionId }, initOverrides);
        return await response.value();
    }

    /**
     * Remove control definitions from import file
     */
    async removeImportFileControlDefinitionsRaw(requestParameters: ImportFileControlDefinitionApiRemoveImportFileControlDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.removeImportFileControlsRequest === null || requestParameters.removeImportFileControlsRequest === undefined) {
            throw new runtime.RequiredError('removeImportFileControlsRequest','Required parameter requestParameters.removeImportFileControlsRequest was null or undefined when calling removeImportFileControlDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-control-definition/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveImportFileControlsRequestToJSON(requestParameters.removeImportFileControlsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Remove control definitions from import file
     */
    async removeImportFileControlDefinitions(removeImportFileControlsRequest: RemoveImportFileControlsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.removeImportFileControlDefinitionsRaw({ removeImportFileControlsRequest: removeImportFileControlsRequest }, initOverrides);
        return await response.value();
    }

}
