import React, { Fragment, ReactElement, Ref, createRef, forwardRef, useCallback, useMemo, useState } from 'react';
import { Cell, flexRender, Header, HeaderGroup, Table as TanstackTable } from '@tanstack/react-table';
import { get } from 'lodash';
import {
    CheckboxItem,
    Dropdown,
    IconButton,
    Pagination,
    SortOrder,
    Table,
    TBody,
    TCell,
    THead,
    THeadCell,
    TRow
} from '@mc-ui/react-components';
import { TableDensity } from '../../store/preferences-context';
import { EllipsisVerticalIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import ErrorBoundary from '../common/ErrorBoundary';
import { ACTIONS_COLUMN } from '../../utils/commonConst';

type TableViewProps<T extends object> = {
    table: TanstackTable<T>;
    allowPagination?: boolean;
};

type HiddenSizingHeaderProps<T extends object> = {
    headerGroup: HeaderGroup<T>;
};

const HiddenSizingHeader = <T extends object>({ headerGroup }: HiddenSizingHeaderProps<T>) => {
    const filterResizableHeaderGroups = (headerGroup: HeaderGroup<T>) => {
        return headerGroup.headers
            .map((header, idx, arr) => {
                const nextIdx = arr.findIndex((next, nextIdx) => nextIdx >= idx && !next.column.getCanResize());

                return {
                    canResize: header.column.getCanResize(),
                    colSpan: nextIdx > -1 ? nextIdx - idx : arr.length - idx,
                    id: header.id
                };
            })
            .filter((header, idx, arr) => {
                if (!header.canResize) {
                    return true;
                }
                if (idx > 0 && arr[idx - 1].canResize) {
                    return false;
                }
                return true;
            });
    };

    return (
        <TRow
            key={headerGroup.id}
            className='h-0'
            aria-hidden={true}
        >
            <THeadCell
                className=''
                density='none'
            />
            {filterResizableHeaderGroups(headerGroup).map(({ colSpan, id }, idx, arr) => {
                const groups = arr
                    .filter((h) => h.canResize)
                    .map((h) => h.colSpan)
                    .reduce((prev, curr) => prev + curr, 0);
                return (
                    <THeadCell
                        key={id + '_' + idx}
                        className=''
                        density='none'
                        colSpan={colSpan !== 0 ? colSpan : undefined}
                        style={
                            colSpan !== 0
                                ? {
                                      width: Math.round((100 / groups) * colSpan) + '%'
                                  }
                                : undefined
                        }
                    />
                );
            })}
        </TRow>
    );
};

const getRowAdditionalClasses = (isDeleted: boolean, depth: number, isDisplayed: boolean) => {
    const classes = [];
    if (isDeleted) {
        classes.push('text-light-textDisabled dark:text-dark-textDisabled');
    }
    if (depth !== 0) {
        classes.push('bg-mc-blue-50 dark:bg-mc-grey-800');
    }
    if (isDisplayed === false) {
        classes.push('font-bold');
    }
    return classes.join(' ');
};

const getCellClasses = (index: number, size: number, additionalClassName?: string) => {
    const classes: string[] = [];
    if (index === size - 1) {
        classes.push('pr-4 sm:pr-6 lg:pr-8');
    }
    if (additionalClassName) {
        classes.push(additionalClassName);
    }
    return classes.join(' ');
};

const formatCell = (header: any) => {
    if (header.isPlaceholder) {
        return null;
    }
    return flexRender(header.column.columnDef.header, header.getContext());
};

const setVisibilityBatch = <T,>(table: TanstackTable<T>, visible: boolean) => {
    const update = table
        .getAllFlatColumns()
        .filter((c) => c.getCanHide())
        .map((c) => c.id)
        .reduce((a, c) => {
            return { ...a, [c]: visible };
        }, {});
    table.setColumnVisibility(update);
};

const getSortableProps = <T,>(
    table: TanstackTable<T>,
    header: Header<T, unknown>
):
    | {
          sortable: true;
          sort: SortOrder;
          onToggleSort?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
          orderNo?: number;
      }
    | { sortable: false } => {
    if (header.column.getCanSort()) {
        return {
            sortable: true,
            onToggleSort: header.column.getToggleSortingHandler(),
            sort: header.column.getIsSorted(),
            orderNo: table.getState().sorting.length > 1 && header.column.getSortIndex() > -1 ? header.column.getSortIndex() + 1 : undefined
        };
    }

    return {
        sortable: false
    };
};

const getMoveableProps = <T,>(
    header: Header<T, unknown>,
    onDragHandler: (draggedColumnName: string) => void,
    onDropHandler: (droppedOnColumnName: string) => void
):
    | {
          draggable: true;
          dragHandler: () => void;
          dropHandler: () => void;
      }
    | {
          draggable: false;
          dragHandler?: never;
          dropHandler?: never;
      } => {
    if (header.column.columnDef.meta?.moveable) {
        return {
            draggable: true,
            dragHandler: () => onDragHandler(header.id),
            dropHandler: () => onDropHandler(header.id)
        };
    } else {
        return {
            draggable: false
        };
    }
};

type TableRowProps<T> = {
    selected: boolean;
    additionalClassName: string;
    visibleCells: Cell<T, unknown>[];
    density: TableDensity;
};

type TableRowComponent = <T>(props: TableRowProps<T>) => ReactElement | null;

const TableRow: TableRowComponent = ({ selected, additionalClassName, visibleCells, density }) => {
    // TODO lepsie by bolo pouzit priamo React.memo ale neviem spravne preniest genericky typ do Cell<T, unknown>[]
    return useMemo(() => {
        return (
            <TRow
                selected={selected}
                additionalClassName={additionalClassName}
            >
                <TCell className='sticky left-0 w-min border-b border-light-borderLight dark:border-dark-borderLight bg-inherit'></TCell>
                {visibleCells.map((cell, idx, arr) => {
                    return (
                        <TCell
                            density={density}
                            key={cell.id + '_' + idx}
                            additionalClassName={getCellClasses(idx, arr.length, cell.column.columnDef.meta?.additionalClassName)}
                            className={cell.column.columnDef.meta?.className}
                        >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TCell>
                    );
                })}
            </TRow>
        );
    }, [selected, additionalClassName, visibleCells, density]);
};

const TableView = forwardRef(<T extends object>({ table, allowPagination = true }: TableViewProps<T>, ref?: Ref<HTMLDivElement>) => {
    const deletedKey = table.options.meta?.deletedKey;
    const displayedKey = table.options.meta?.displayedKey;
    const density = table.options.meta?.density ?? 'normal';
    const [isDragging, setIsDragging] = useState(false);
    const [draggedColumn, setDraggedColumn] = useState<string | null>(null);

    const hideableColumns = table
        .getAllFlatColumns()
        .filter((c) => c.getCanHide())
        .map<CheckboxItem>((c) => {
            let label = c.columnDef.meta?.title ?? c.columnDef.header?.toString() ?? c.columnDef.id ?? '';
            if (typeof c.columnDef.header === 'function' && !c.columnDef.meta?.title) {
                throw new Error('Tabuľka používa headerComponent, musí byť uvedený atribút meta.title');
            }

            return {
                label,
                checked: c.getIsVisible(),
                onChange: (visible: boolean) => {
                    c.toggleVisibility(visible);
                }
            };
        });

    const visibilityDropdown =
        hideableColumns.length > 0 ? (
            <Dropdown
                placement='bottom-start'
                items={[
                    ...hideableColumns,
                    {
                        label: 'Zobraziť všetky',
                        icon: EyeIcon,
                        onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setVisibilityBatch(table, true);
                        },
                        className:
                            'sticky border-t hover:bg-light-hover dark:hover:bg-dark-hover border-light-border dark:border-dark-border bottom-9 bg-light-backgroundLight dark:bg-dark-backgroundLight text-light-text dark:text-dark-text group flex w-full items-center text-left px-4 py-2 text-sm'
                    },
                    {
                        label: 'Skryť všetky',
                        icon: EyeSlashIcon,
                        onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setVisibilityBatch(table, false);
                        },
                        className:
                            'sticky bottom-0 bg-light-backgroundLight dark:bg-dark-backgroundLight hover:bg-light-hover dark:hover:bg-dark-hover text-light-text dark:text-dark-text group flex w-full items-center text-left px-4 py-2 text-sm'
                    }
                ]}
            >
                <IconButton
                    icon={EllipsisVerticalIcon}
                    color='transparent'
                    size='dense'
                    title='Nastavenia stĺpcov'
                    autoFocus={false}
                />
            </Dropdown>
        ) : undefined;

    const wrapperRef = useMemo(() => {
        if (!ref) {
            return createRef<HTMLDivElement>();
        } else {
            return ref;
        }
    }, [ref]);

    const handleMouseDown = (e: React.MouseEvent<HTMLTableElement>) => {
        if (e.ctrlKey) {
            e.preventDefault();
            setIsDragging(true);
        }
    };

    const handleMouseUpOrLeave = (e: React.MouseEvent<HTMLTableElement>) => {
        setIsDragging(false);
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLTableElement>) => {
        if (isDragging && e.ctrlKey) {
            if (wrapperRef && 'current' in wrapperRef) {
                wrapperRef.current?.scrollBy(-1 * e.movementX, 0);
            }
        }
    };

    const onDragHandler = useCallback((draggedColumnName: string) => {
        setDraggedColumn(draggedColumnName);
    }, []);

    const onDropHandler = useCallback(
        (droppedOnColumnName: string) => {
            const columnOrder = table
                .getAllFlatColumns()
                .map((column) => ({ index: column.getIndex(), id: column.id }))
                .sort((columnA, columnB) => {
                    if (columnA.index === -1) {
                        return 1;
                    }
                    if (columnB.index === -1) {
                        return -1;
                    }
                    if (columnA.index > columnB.index) {
                        return 1;
                    }
                    if (columnA.index < columnB.index) {
                        return -1;
                    }
                    return 0;
                })
                .map((column) => column.id);

            if (draggedColumn && droppedOnColumnName) {
                const order = [...columnOrder];
                const fromIndex = order.findIndex((o) => o === draggedColumn);
                const _toIndex = order.findIndex((o) => o === droppedOnColumnName);
                if (fromIndex > -1 && _toIndex > -1 && fromIndex !== _toIndex) {
                    order.splice(fromIndex, 1); // remove source from original position
                    const toIndex = order.findIndex((o) => o === droppedOnColumnName);
                    if (fromIndex - _toIndex > 0) {
                        order.splice(toIndex, 0, draggedColumn);
                    } else {
                        order.splice(toIndex + 1, 0, draggedColumn);
                    }
                    const actionsIdx = order.findIndex((o) => o === ACTIONS_COLUMN);
                    if (actionsIdx > -1) {
                        // move actions to end
                        order.splice(actionsIdx, 1);
                        order.splice(order.length, 0, ACTIONS_COLUMN);
                    }
                    table.setColumnOrder(order);
                }
            }
            setDraggedColumn(null);
        },
        [draggedColumn, table]
    );

    return (
        <ErrorBoundary title='Počas vykresľovania tabuľky s dátami nastala chyba.'>
            <div
                className='relative mb-2 overflow-x-auto pb-3 text-light-textDark dark:text-dark-textDark'
                ref={wrapperRef}
            >
                <Table
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUpOrLeave}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseUpOrLeave}
                    additionalClassName={isDragging ? 'cursor-grabbing' : ''}
                >
                    <THead>
                        {table.getHeaderGroups().map((headerGroup, idx) => (
                            <Fragment key={headerGroup.id + '_' + idx}>
                                <HiddenSizingHeader headerGroup={headerGroup} />
                                <TRow additionalClassName='h-16'>
                                    <THeadCell className='sticky left-0 z-10 w-min border-b border-light-border dark:border-dark-border bg-inherit py-2 pl-2 pr-0'>
                                        {idx === 0 && visibilityDropdown}
                                    </THeadCell>
                                    {headerGroup.headers.map((header, idx, arr) => (
                                        <THeadCell
                                            key={header.id + '_' + idx}
                                            colSpan={header.colSpan}
                                            additionalClassName={getCellClasses(
                                                idx,
                                                arr.length,
                                                header.column.columnDef.meta?.additionalClassName
                                            )}
                                            className={header.column.columnDef.meta?.className}
                                            resizable={false}
                                            style={{
                                                width: header.getSize()
                                            }}
                                            {...getSortableProps(table, header)}
                                            {...getMoveableProps(header, onDragHandler, onDropHandler)}
                                        >
                                            {formatCell(header)}
                                        </THeadCell>
                                    ))}
                                </TRow>
                            </Fragment>
                        ))}
                    </THead>
                    <TBody>
                        {table.getRowModel().rows.map((row) => {
                            const hasRowId = row.id !== undefined && row.id !== null && row.id.toString().trim() !== '';
                            const key = hasRowId ? `${row.id}_${row.index}_${row.depth}` : `${row.index}_${row.depth}`;
                            return (
                                <TableRow
                                    key={key}
                                    selected={row.getIsSelected()}
                                    additionalClassName={getRowAdditionalClasses(
                                        deletedKey ? !!get(row.original, deletedKey) : false,
                                        row.depth,
                                        displayedKey === undefined ? true : get(row.original, displayedKey) === true ? true : false
                                    )}
                                    visibleCells={row.getVisibleCells()}
                                    density={density}
                                />
                            );
                        })}
                    </TBody>
                </Table>
            </div>
            {table.options.meta?.totalElements === 0 && table.options.meta?.loaded && (
                <div className='text-center'>
                    <p className='pb-4 text-sm font-medium text-light-textLight dark:text-dark-textLight'>Nenájdené žiadne záznamy</p>
                </div>
            )}
            {allowPagination && (
                <Pagination
                    additionalClassName='pb-10'
                    onPageChange={table.setPageIndex}
                    page={table.getState().pagination.pageIndex}
                    numOfPages={table.getPageCount()}
                />
            )}
        </ErrorBoundary>
    );
});

export default TableView as <T extends object>(props: TableViewProps<T> & { ref?: Ref<HTMLDivElement> }) => JSX.Element;
