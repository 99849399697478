/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.30.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoringPointCriteria
 */
export interface MonitoringPointCriteria {
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointCriteria
     */
    searchField?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointCriteria
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointCriteria
     */
    placeName?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointCriteria
     */
    effectiveTo?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointCriteria
     */
    effectiveFrom?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringPointCriteria
     */
    deleted?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    regionType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    districtType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    instBranchType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    montPointType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    monitoringType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    partialBasinType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    subBasinType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    areaType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    environmentType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    affectedType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    partialHgrType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    waterShapeId?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    id?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    historyGroupIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    userGroupIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    environmentIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    basinType?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitoringPointCriteria
     */
    balanceCode?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    positionType?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof MonitoringPointCriteria
     */
    vhmKm?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    assesmentWaterFlowType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    assesmentBasinType?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointCriteria
     */
    rangeDateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointCriteria
     */
    rangeDateTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringPointCriteria
     */
    useEqual?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringPointCriteria
     */
    historical?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitoringPointCriteria
     */
    environmentCodes?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPointCriteria
     */
    installationIds?: Array<number>;
}

/**
 * Check if a given object implements the MonitoringPointCriteria interface.
 */
export function instanceOfMonitoringPointCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitoringPointCriteriaFromJSON(json: any): MonitoringPointCriteria {
    return MonitoringPointCriteriaFromJSONTyped(json, false);
}

export function MonitoringPointCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringPointCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'placeName': !exists(json, 'placeName') ? undefined : json['placeName'],
        'effectiveTo': !exists(json, 'effectiveTo') ? undefined : json['effectiveTo'],
        'effectiveFrom': !exists(json, 'effectiveFrom') ? undefined : json['effectiveFrom'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'regionType': !exists(json, 'regionType') ? undefined : json['regionType'],
        'districtType': !exists(json, 'districtType') ? undefined : json['districtType'],
        'instBranchType': !exists(json, 'instBranchType') ? undefined : json['instBranchType'],
        'montPointType': !exists(json, 'montPointType') ? undefined : json['montPointType'],
        'monitoringType': !exists(json, 'monitoringType') ? undefined : json['monitoringType'],
        'partialBasinType': !exists(json, 'partialBasinType') ? undefined : json['partialBasinType'],
        'subBasinType': !exists(json, 'subBasinType') ? undefined : json['subBasinType'],
        'areaType': !exists(json, 'areaType') ? undefined : json['areaType'],
        'environmentType': !exists(json, 'environmentType') ? undefined : json['environmentType'],
        'affectedType': !exists(json, 'affectedType') ? undefined : json['affectedType'],
        'partialHgrType': !exists(json, 'partialHgrType') ? undefined : json['partialHgrType'],
        'waterShapeId': !exists(json, 'waterShapeId') ? undefined : json['waterShapeId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'historyGroupIds': !exists(json, 'historyGroupIds') ? undefined : json['historyGroupIds'],
        'userGroupIds': !exists(json, 'userGroupIds') ? undefined : json['userGroupIds'],
        'environmentIds': !exists(json, 'environmentIds') ? undefined : json['environmentIds'],
        'basinType': !exists(json, 'basinType') ? undefined : json['basinType'],
        'balanceCode': !exists(json, 'balanceCode') ? undefined : json['balanceCode'],
        'positionType': !exists(json, 'positionType') ? undefined : json['positionType'],
        'vhmKm': !exists(json, 'vhmKm') ? undefined : json['vhmKm'],
        'assesmentWaterFlowType': !exists(json, 'assesmentWaterFlowType') ? undefined : json['assesmentWaterFlowType'],
        'assesmentBasinType': !exists(json, 'assesmentBasinType') ? undefined : json['assesmentBasinType'],
        'rangeDateFrom': !exists(json, 'rangeDateFrom') ? undefined : json['rangeDateFrom'],
        'rangeDateTo': !exists(json, 'rangeDateTo') ? undefined : json['rangeDateTo'],
        'useEqual': !exists(json, 'useEqual') ? undefined : json['useEqual'],
        'historical': !exists(json, 'historical') ? undefined : json['historical'],
        'environmentCodes': !exists(json, 'environmentCodes') ? undefined : json['environmentCodes'],
        'installationIds': !exists(json, 'installationIds') ? undefined : json['installationIds'],
    };
}

export function MonitoringPointCriteriaToJSON(value?: MonitoringPointCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchField': value.searchField,
        'code': value.code,
        'placeName': value.placeName,
        'effectiveTo': value.effectiveTo,
        'effectiveFrom': value.effectiveFrom,
        'deleted': value.deleted,
        'regionType': value.regionType,
        'districtType': value.districtType,
        'instBranchType': value.instBranchType,
        'montPointType': value.montPointType,
        'monitoringType': value.monitoringType,
        'partialBasinType': value.partialBasinType,
        'subBasinType': value.subBasinType,
        'areaType': value.areaType,
        'environmentType': value.environmentType,
        'affectedType': value.affectedType,
        'partialHgrType': value.partialHgrType,
        'waterShapeId': value.waterShapeId,
        'id': value.id,
        'historyGroupIds': value.historyGroupIds,
        'userGroupIds': value.userGroupIds,
        'environmentIds': value.environmentIds,
        'basinType': value.basinType,
        'balanceCode': value.balanceCode,
        'positionType': value.positionType,
        'vhmKm': value.vhmKm,
        'assesmentWaterFlowType': value.assesmentWaterFlowType,
        'assesmentBasinType': value.assesmentBasinType,
        'rangeDateFrom': value.rangeDateFrom,
        'rangeDateTo': value.rangeDateTo,
        'useEqual': value.useEqual,
        'historical': value.historical,
        'environmentCodes': value.environmentCodes,
        'installationIds': value.installationIds,
    };
}

