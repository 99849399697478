/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.30.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EndpointExceptionBody,
  MapPluginInfo,
} from '../models/index';
import {
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    MapPluginInfoFromJSON,
    MapPluginInfoToJSON,
} from '../models/index';

/**
 * 
 */
export class MapPluginApi extends runtime.BaseAPI {

    /**
     */
    async getMapPluginInfoSoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MapPluginInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/map-plugin/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MapPluginInfoFromJSON(jsonValue));
    }

    /**
     */
    async getMapPluginInfoSo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MapPluginInfo> {
        const response = await this.getMapPluginInfoSoRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async recalculateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/map-plugin/recalculate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async recalculate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.recalculateRaw(initOverrides);
    }

}
