/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FilterDataSo } from './FilterDataSo';
import {
    FilterDataSoFromJSON,
    FilterDataSoFromJSONTyped,
    FilterDataSoToJSON,
} from './FilterDataSo';
import type { PageableObject } from './PageableObject';
import {
    PageableObjectFromJSON,
    PageableObjectFromJSONTyped,
    PageableObjectToJSON,
} from './PageableObject';
import type { SortObject } from './SortObject';
import {
    SortObjectFromJSON,
    SortObjectFromJSONTyped,
    SortObjectToJSON,
} from './SortObject';

/**
 * 
 * @export
 * @interface PageFilterDataSo
 */
export interface PageFilterDataSo {
    /**
     * 
     * @type {number}
     * @memberof PageFilterDataSo
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFilterDataSo
     */
    totalPages?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageFilterDataSo
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageFilterDataSo
     */
    numberOfElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFilterDataSo
     */
    size?: number;
    /**
     * 
     * @type {Array<FilterDataSo>}
     * @memberof PageFilterDataSo
     */
    content?: Array<FilterDataSo>;
    /**
     * 
     * @type {number}
     * @memberof PageFilterDataSo
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageFilterDataSo
     */
    sort?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageFilterDataSo
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFilterDataSo
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFilterDataSo
     */
    empty?: boolean;
}

/**
 * Check if a given object implements the PageFilterDataSo interface.
 */
export function instanceOfPageFilterDataSo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PageFilterDataSoFromJSON(json: any): PageFilterDataSo {
    return PageFilterDataSoFromJSONTyped(json, false);
}

export function PageFilterDataSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageFilterDataSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalElements': !exists(json, 'totalElements') ? undefined : json['totalElements'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'pageable': !exists(json, 'pageable') ? undefined : PageableObjectFromJSON(json['pageable']),
        'numberOfElements': !exists(json, 'numberOfElements') ? undefined : json['numberOfElements'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(FilterDataSoFromJSON)),
        'number': !exists(json, 'number') ? undefined : json['number'],
        'sort': !exists(json, 'sort') ? undefined : SortObjectFromJSON(json['sort']),
        'first': !exists(json, 'first') ? undefined : json['first'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'empty': !exists(json, 'empty') ? undefined : json['empty'],
    };
}

export function PageFilterDataSoToJSON(value?: PageFilterDataSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
        'pageable': PageableObjectToJSON(value.pageable),
        'numberOfElements': value.numberOfElements,
        'size': value.size,
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(FilterDataSoToJSON)),
        'number': value.number,
        'sort': SortObjectToJSON(value.sort),
        'first': value.first,
        'last': value.last,
        'empty': value.empty,
    };
}

