/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeItemAttributeIdentificationRequest } from './CodeItemAttributeIdentificationRequest';
import {
    CodeItemAttributeIdentificationRequestFromJSON,
    CodeItemAttributeIdentificationRequestFromJSONTyped,
    CodeItemAttributeIdentificationRequestToJSON,
} from './CodeItemAttributeIdentificationRequest';
import type { CodelistSearchDefinitionItemRequest } from './CodelistSearchDefinitionItemRequest';
import {
    CodelistSearchDefinitionItemRequestFromJSON,
    CodelistSearchDefinitionItemRequestFromJSONTyped,
    CodelistSearchDefinitionItemRequestToJSON,
} from './CodelistSearchDefinitionItemRequest';
import type { ControlDefinitionItemRequest } from './ControlDefinitionItemRequest';
import {
    ControlDefinitionItemRequestFromJSON,
    ControlDefinitionItemRequestFromJSONTyped,
    ControlDefinitionItemRequestToJSON,
} from './ControlDefinitionItemRequest';
import type { DataRegistryAttributeIdentificationRequest } from './DataRegistryAttributeIdentificationRequest';
import {
    DataRegistryAttributeIdentificationRequestFromJSON,
    DataRegistryAttributeIdentificationRequestFromJSONTyped,
    DataRegistryAttributeIdentificationRequestToJSON,
} from './DataRegistryAttributeIdentificationRequest';
import type { DataRegistrySearchDefinitionItemRequest } from './DataRegistrySearchDefinitionItemRequest';
import {
    DataRegistrySearchDefinitionItemRequestFromJSON,
    DataRegistrySearchDefinitionItemRequestFromJSONTyped,
    DataRegistrySearchDefinitionItemRequestToJSON,
} from './DataRegistrySearchDefinitionItemRequest';

/**
 * 
 * @export
 * @interface ControlRequest
 */
export interface ControlRequest {
    /**
     * 
     * @type {string}
     * @memberof ControlRequest
     */
    controlType?: ControlRequestControlTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ControlRequest
     */
    controlCriticalityType?: ControlRequestControlCriticalityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ControlRequest
     */
    controlDataType?: ControlRequestControlDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ControlRequest
     */
    referenceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlRequest
     */
    controlOccurrenceReferenceAttributeType?: ControlRequestControlOccurrenceReferenceAttributeTypeEnum;
    /**
     * 
     * @type {Array<ControlDefinitionItemRequest>}
     * @memberof ControlRequest
     */
    controlDefinitionItems?: Array<ControlDefinitionItemRequest>;
    /**
     * 
     * @type {Array<DataRegistrySearchDefinitionItemRequest>}
     * @memberof ControlRequest
     */
    dataRegistrySearchDefinitionItems?: Array<DataRegistrySearchDefinitionItemRequest>;
    /**
     * 
     * @type {Array<DataRegistryAttributeIdentificationRequest>}
     * @memberof ControlRequest
     */
    dataRegistryAttributeIdentifications?: Array<DataRegistryAttributeIdentificationRequest>;
    /**
     * 
     * @type {Array<CodelistSearchDefinitionItemRequest>}
     * @memberof ControlRequest
     */
    codelistSearchDefinitionItems?: Array<CodelistSearchDefinitionItemRequest>;
    /**
     * 
     * @type {Array<CodeItemAttributeIdentificationRequest>}
     * @memberof ControlRequest
     */
    codeItemAttributeIdentifications?: Array<CodeItemAttributeIdentificationRequest>;
}


/**
 * @export
 */
export const ControlRequestControlTypeEnum = {
    FileStructureControl: 'FILE_STRUCTURE_CONTROL',
    FileExtensionControl: 'FILE_EXTENSION_CONTROL',
    FileNameControl: 'FILE_NAME_CONTROL',
    HeaderControl: 'HEADER_CONTROL',
    LaboratoryControl: 'LABORATORY_CONTROL',
    ComparisonOfDeterminants: 'COMPARISON_OF_DETERMINANTS',
    AmountOfWaterTakenFilledEveryMonth: 'AMOUNT_OF_WATER_TAKEN_FILLED_EVERY_MONTH',
    ValueOfDeterminantInVNotHigherInP: 'VALUE_OF_DETERMINANT_IN_V_NOT_HIGHER_IN_P',
    AmountOfWaterDischargedFilledEveryMonth: 'AMOUNT_OF_WATER_DISCHARGED_FILLED_EVERY_MONTH',
    CodelistDeterminantControl: 'CODELIST_DETERMINANT_CONTROL',
    DuplicityNecSampleTypeDate: 'DUPLICITY_NEC_SAMPLE_TYPE_DATE',
    DuplicityNecDeterminantPV: 'DUPLICITY_NEC_DETERMINANT_P_V',
    ComplianceOfSampleDatesWithYear: 'COMPLIANCE_OF_SAMPLE_DATES_WITH_YEAR',
    DuplicitySampleTypeDate: 'DUPLICITY_SAMPLE_TYPE_DATE',
    TerrainAndLaboratoryDateMismatch: 'TERRAIN_AND_LABORATORY_DATE_MISMATCH',
    WeeklyWaterTemperatureWithinTimeSeries: 'WEEKLY_WATER_TEMPERATURE_WITHIN_TIME_SERIES',
    WeeklyWaterLevelWithinTimeSeries: 'WEEKLY_WATER_LEVEL_WITHIN_TIME_SERIES',
    HourlyWaterLevelWithinTimeSeries: 'HOURLY_WATER_LEVEL_WITHIN_TIME_SERIES',
    HourlyWaterTemperatureWithinTimeSeries: 'HOURLY_WATER_TEMPERATURE_WITHIN_TIME_SERIES',
    ValueExceededExtreme: 'VALUE_EXCEEDED_EXTREME',
    WaterLevelEmptyTemperatureEmpty: 'WATER_LEVEL_EMPTY_TEMPERATURE_EMPTY',
    YieldOfSpringEmptyTemperatureEmpty: 'YIELD_OF_SPRING_EMPTY_TEMPERATURE_EMPTY',
    YieldOfSpringRange: 'YIELD_OF_SPRING_RANGE',
    StateOfWaterInProbeRange: 'STATE_OF_WATER_IN_PROBE_RANGE',
    WaterTemperatureRangeUndergroundWater: 'WATER_TEMPERATURE_RANGE_UNDERGROUND_WATER',
    NonExistenceMonPointCode: 'NON_EXISTENCE_MON_POINT_CODE',
    NonExistenceBalanceCode: 'NON_EXISTENCE_BALANCE_CODE',
    BalanceCodeBlockedByDate: 'BALANCE_CODE_BLOCKED_BY_DATE',
    NoMonitoringActivityOnMonPoint: 'NO_MONITORING_ACTIVITY_ON_MON_POINT',
    LoqAgainstDevDetectLimit: 'LOQ_AGAINST_DEV_DETECT_LIMIT',
    LoqAgainstChvoDeterminantLimit: 'LOQ_AGAINST_CHVO_DETERMINANT_LIMIT',
    LoqAgainstAnMethodic: 'LOQ_AGAINST_AN_METHODIC',
    TnmnAnalysisMissingMandatoryData: 'TNMN_ANALYSIS_MISSING_MANDATORY_DATA',
    ExistenceSampleWhenUploadingAnalyses: 'EXISTENCE_SAMPLE_WHEN_UPLOADING_ANALYSES',
    OrganicComponentRange: 'ORGANIC_COMPONENT_RANGE',
    TurbidityRange: 'TURBIDITY_RANGE',
    FlowRange: 'FLOW_RANGE',
    WaterLevelRange: 'WATER_LEVEL_RANGE',
    DrySeason: 'DRY_SEASON',
    WaterTemperatureRangeSurfaceWater: 'WATER_TEMPERATURE_RANGE_SURFACE_WATER',
    NonCriticalWaterTemperatureRangeSurfaceWater: 'NON_CRITICAL_WATER_TEMPERATURE_RANGE_SURFACE_WATER',
    NonCriticalWaterTemperatureRangeUndergroundWater: 'NON_CRITICAL_WATER_TEMPERATURE_RANGE_UNDERGROUND_WATER',
    NonCriticalYieldOfSpringRange: 'NON_CRITICAL_YIELD_OF_SPRING_RANGE',
    StateOfWaterInProbeDepthOfWellAndHighOfMeasuringPoint: 'STATE_OF_WATER_IN_PROBE_DEPTH_OF_WELL_AND_HIGH_OF_MEASURING_POINT',
    NonCriticalStateOfWaterInProbeRange: 'NON_CRITICAL_STATE_OF_WATER_IN_PROBE_RANGE',
    CationAnionTest: 'CATION_ANION_TEST',
    SigmaTest: 'SIGMA_TEST',
    ExistenceDateOfDataInTimePeriodOfMountPointVersion: 'EXISTENCE_DATE_OF_DATA_IN_TIME_PERIOD_OF_MOUNT_POINT_VERSION',
    TotalAndDissolvedMetals: 'TOTAL_AND_DISSOLVED_METALS',
    BskChsk: 'BSK_CHSK',
    NitrogenBalance: 'NITROGEN_BALANCE',
    ComplianceWithGenerallyApplicableChemicalRules: 'COMPLIANCE_WITH_GENERALLY_APPLICABLE_CHEMICAL_RULES',
    ZeroAnalysisValues: 'ZERO_ANALYSIS_VALUES',
    DateFormat: 'DATE_FORMAT',
    MountPointNoVersion: 'MOUNT_POINT_NO_VERSION',
    CorrectnessOfUnit: 'CORRECTNESS_OF_UNIT',
    ExtremeData: 'EXTREME_DATA',
    NumericalDataOutsideAllowedRange: 'NUMERICAL_DATA_OUTSIDE_ALLOWED_RANGE',
    InvalidDateRange: 'INVALID_DATE_RANGE',
    UnknownCodeitemCode: 'UNKNOWN_CODEITEM_CODE',
    UnknownMountPointCode: 'UNKNOWN_MOUNT_POINT_CODE',
    DecimalSeparatorInterpretation: 'DECIMAL_SEPARATOR_INTERPRETATION',
    ImportFileDataRegistryDuplicity: 'IMPORT_FILE_DATA_REGISTRY_DUPLICITY',
    IncompleteTimeSeries: 'INCOMPLETE_TIME_SERIES',
    WarningImportFileRowDuplicity: 'WARNING_IMPORT_FILE_ROW_DUPLICITY',
    ImportFileRowDuplicityKeyAttributes: 'IMPORT_FILE_ROW_DUPLICITY_KEY_ATTRIBUTES',
    IncorrectDataType: 'INCORRECT_DATA_TYPE',
    ValueExceededExtremeQuantityUndergroundWater: 'VALUE_EXCEEDED_EXTREME_QUANTITY_UNDERGROUND_WATER',
    ValueExceededExtremeQuantitySurfaceWater: 'VALUE_EXCEEDED_EXTREME_QUANTITY_SURFACE_WATER',
    WaterLevelEmptyTemperatureEmptyDr: 'WATER_LEVEL_EMPTY_TEMPERATURE_EMPTY_DR',
    YieldOfSpringEmptyTemperatureEmptyDr: 'YIELD_OF_SPRING_EMPTY_TEMPERATURE_EMPTY_DR',
    DeterminantInDeterminantGroup: 'DETERMINANT_IN_DETERMINANT_GROUP',
    ValidityOfNotificationWithNotificationOfCommencement: 'VALIDITY_OF_NOTIFICATION_WITH_NOTIFICATION_OF_COMMENCEMENT'
} as const;
export type ControlRequestControlTypeEnum = typeof ControlRequestControlTypeEnum[keyof typeof ControlRequestControlTypeEnum];

/**
 * @export
 */
export const ControlRequestControlCriticalityTypeEnum = {
    Critical: 'CRITICAL',
    NonCritical: 'NON_CRITICAL',
    Warning: 'WARNING'
} as const;
export type ControlRequestControlCriticalityTypeEnum = typeof ControlRequestControlCriticalityTypeEnum[keyof typeof ControlRequestControlCriticalityTypeEnum];

/**
 * @export
 */
export const ControlRequestControlDataTypeEnum = {
    DataRegistry: 'DATA_REGISTRY',
    Codelist: 'CODELIST',
    MonPoint: 'MON_POINT',
    None: 'NONE',
    TimeSeries: 'TIME_SERIES'
} as const;
export type ControlRequestControlDataTypeEnum = typeof ControlRequestControlDataTypeEnum[keyof typeof ControlRequestControlDataTypeEnum];

/**
 * @export
 */
export const ControlRequestControlOccurrenceReferenceAttributeTypeEnum = {
    OneInMainRow: 'ONE_IN_MAIN_ROW',
    OneInEachDependentRow: 'ONE_IN_EACH_DEPENDENT_ROW',
    MoreInMainRow: 'MORE_IN_MAIN_ROW',
    OneInRow: 'ONE_IN_ROW',
    None: 'NONE'
} as const;
export type ControlRequestControlOccurrenceReferenceAttributeTypeEnum = typeof ControlRequestControlOccurrenceReferenceAttributeTypeEnum[keyof typeof ControlRequestControlOccurrenceReferenceAttributeTypeEnum];


/**
 * Check if a given object implements the ControlRequest interface.
 */
export function instanceOfControlRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlRequestFromJSON(json: any): ControlRequest {
    return ControlRequestFromJSONTyped(json, false);
}

export function ControlRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'controlType': !exists(json, 'controlType') ? undefined : json['controlType'],
        'controlCriticalityType': !exists(json, 'controlCriticalityType') ? undefined : json['controlCriticalityType'],
        'controlDataType': !exists(json, 'controlDataType') ? undefined : json['controlDataType'],
        'referenceCode': !exists(json, 'referenceCode') ? undefined : json['referenceCode'],
        'controlOccurrenceReferenceAttributeType': !exists(json, 'controlOccurrenceReferenceAttributeType') ? undefined : json['controlOccurrenceReferenceAttributeType'],
        'controlDefinitionItems': !exists(json, 'controlDefinitionItems') ? undefined : ((json['controlDefinitionItems'] as Array<any>).map(ControlDefinitionItemRequestFromJSON)),
        'dataRegistrySearchDefinitionItems': !exists(json, 'dataRegistrySearchDefinitionItems') ? undefined : ((json['dataRegistrySearchDefinitionItems'] as Array<any>).map(DataRegistrySearchDefinitionItemRequestFromJSON)),
        'dataRegistryAttributeIdentifications': !exists(json, 'dataRegistryAttributeIdentifications') ? undefined : ((json['dataRegistryAttributeIdentifications'] as Array<any>).map(DataRegistryAttributeIdentificationRequestFromJSON)),
        'codelistSearchDefinitionItems': !exists(json, 'codelistSearchDefinitionItems') ? undefined : ((json['codelistSearchDefinitionItems'] as Array<any>).map(CodelistSearchDefinitionItemRequestFromJSON)),
        'codeItemAttributeIdentifications': !exists(json, 'codeItemAttributeIdentifications') ? undefined : ((json['codeItemAttributeIdentifications'] as Array<any>).map(CodeItemAttributeIdentificationRequestFromJSON)),
    };
}

export function ControlRequestToJSON(value?: ControlRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'controlType': value.controlType,
        'controlCriticalityType': value.controlCriticalityType,
        'controlDataType': value.controlDataType,
        'referenceCode': value.referenceCode,
        'controlOccurrenceReferenceAttributeType': value.controlOccurrenceReferenceAttributeType,
        'controlDefinitionItems': value.controlDefinitionItems === undefined ? undefined : ((value.controlDefinitionItems as Array<any>).map(ControlDefinitionItemRequestToJSON)),
        'dataRegistrySearchDefinitionItems': value.dataRegistrySearchDefinitionItems === undefined ? undefined : ((value.dataRegistrySearchDefinitionItems as Array<any>).map(DataRegistrySearchDefinitionItemRequestToJSON)),
        'dataRegistryAttributeIdentifications': value.dataRegistryAttributeIdentifications === undefined ? undefined : ((value.dataRegistryAttributeIdentifications as Array<any>).map(DataRegistryAttributeIdentificationRequestToJSON)),
        'codelistSearchDefinitionItems': value.codelistSearchDefinitionItems === undefined ? undefined : ((value.codelistSearchDefinitionItems as Array<any>).map(CodelistSearchDefinitionItemRequestToJSON)),
        'codeItemAttributeIdentifications': value.codeItemAttributeIdentifications === undefined ? undefined : ((value.codeItemAttributeIdentifications as Array<any>).map(CodeItemAttributeIdentificationRequestToJSON)),
    };
}

