import { ROLES } from '../../hooks/use-role';

export const allMonPointTypes = [
    '27000001', // MONITOROVACIE MIESTO PV kvalita
    '27000002', // ODBER PV
    '27000003', // VYPÚŠŤANIE do PV
    '27000005', // SONDA kvantita
    '27000006', // ODBER PZV
    '27000007', // PRAMEŇ kvantita
    '27000008', // VODOMERNÁ STANICA
    '27000009', // BILANČNÝ PROFIL (KVANTITA PV)
    '27000011', // TNMN
    '27000012', // VODNÁ NÁDRŽ
    '27000013', // PREVOD VODY
    '27000014', // POSUDKY kvalita
    '27000015', // POSUDKY kvantita
    '27000016', // SUMÁR ODBEROV PZV pre VHB
    '27000017', // NOVÉ ZDROJE PZV
    '27000018', // VHB LOKALITA (KVANTITA PzV)
    '27000019', // PRIESKUMNÉ MONITOROVACIE MIESTO
    '27000020', // VYPÚŠŤANIE do PZV
    '27000021', // SONDA kvalita
    '27000022', // PRAMEŇ kvalita
    '27000023' // VIRTUÁLNA STANICA
    // '27000024' // MM_so_vsetkymi_atributmi
];

export const visibleTabs = {
    undergroundWaterTab: [
        '27000002',
        '27000005',
        '27000006',
        '27000007',
        '27000008',
        '27000009',
        '27000016',
        '27000017',
        '27000018',
        '27000020',
        '27000021',
        '27000022',
        '27000023'
        // '27000024'
    ],
    waterCourseTab: [
        '27000001',
        '27000002',
        '27000003',
        '27000008',
        '27000009',
        '27000011',
        '27000012',
        '27000013',
        '27000014',
        '27000015',
        '27000016',
        '27000019',
        '27000020',
        '27000023'
        // '27000024'
    ],
    waterWithdrawalTab: [
        '27000001',
        '27000002',
        '27000003',
        '27000006',
        '27000008',
        '27000009',
        '27000012',
        '27000013',
        '27000016',
        '27000017',
        '27000018',
        '27000020',
        '27000023'
        // '27000024'
    ],
    otherTab: [
        '27000001',
        '27000003',
        '27000005',
        '27000006',
        '27000007',
        '27000008',
        '27000009',
        '27000011',
        '27000015',
        '27000016',
        '27000017',
        '27000020',
        '27000021',
        '27000022',
        '27000023'
        // '27000024'
    ],
    attachmentsTab: ['27000001', '27000005', '27000007', '27000014', '27000015', '27000021', '27000022' /* , '27000024' */],
    monitoringsTab: [
        '27000001',
        '27000002',
        '27000003',
        '27000005',
        '27000006',
        '27000007',
        '27000008',
        '27000009',
        '27000011',
        '27000012',
        '27000013',
        '27000016',
        '27000017',
        '27000018',
        '27000020',
        '27000021',
        '27000022',
        '27000023'
        // '27000024'
    ],
    pointCategoriesTab: ['27000001' /* , '27000024'*/],
    placesTab: ['27000023']
};

export const hasLocation = [
    '27000001',
    '27000002',
    '27000003',
    '27000005',
    '27000006',
    '27000007',
    '27000008',
    '27000009',
    '27000011',
    '27000012',
    '27000013',
    '27000016',
    '27000017',
    '27000018',
    '27000020',
    '27000021',
    '27000022',
    '27000023'
    // '27000024'
]; // maju fieldGroup Lokacie pod tabom GPS

export const hasVersion = [
    '27000001',
    '27000002',
    '27000003',
    '27000005',
    '27000006',
    '27000007',
    '27000008',
    '27000011',
    '27000016',
    '27000020',
    '27000021',
    '27000022'
    // '27000024'
]; // pri tychto typoch je dovolene ukladat monitorovacie miesta s novou verziou

export const automaticallyGeneratedCode = ['27000017']; // kod monitorovacieho miesta sa bude generovat automaticky

export const hasOnlyMunicipalityAndDistrict = ['27000018']; // pre tento typ mon. miesta sa ukladaju hodnoty iba pre municipalityType a districtType v tabe GPS (landRegisterType, regionType, areaType, countryType su vzdy null)

export const hasNotBasin = ['27000018', '27000019']; // pre tento typ mon. miesta sa ukladaju hodnoty iba pre partialBasinType prípadne pre subBasinType, basinType je vzdy null (nesetujem hodnotu basinType na zaklade partialBasinType) a nezobrazuje sa v tabe Detail

export const requiredMonitorings = ['27000008']; // povinne vyplnit aspon jednu položku v tabe monitorings

export const editableWgs84 = ['27000011' /* , '27000024'*/]; // pri tomto type mon. miesta sa môže meniť a dopĺňať Wgs84latitude a Wgs84longitude (v tab GPS), inde je readOnly

export const blockTechExportAndImport = '27000023'; // pri tomto type mon. miesta sa neda spravit tech. export

export const showMonitorings = ['27000021', '27000022']; // pri tychto typoch mon. miesta sa zobrazi stlpec monitorovacie aktivity (akronymy oddelene ciarkou) vo vyhladavacej tabulke mon. miest

export const showCode = ['27000005', '27000007', '27000021', '27000022']; // pri tychto typoch mon. miesta sa zobrazi (v stlpcoch undergroundWater - gwBodyType a  undergroundWater - mainHgrType) akronym namiesto nazvu vo vyhladavacej tabulke

export const balanceCodeIsCodelistItem = ['27000006', '27000017']; // pri tychto mon. miestach sa uklada (v atribute waterWithdrawal - balanceCode, v zalozke 'Hydrologické charakteristiky') akronym (z ciselnikovej polozky) namiesto klasickeho stringu

export const showCodelistItemsAccordingTypeCategory = ['27000006', '27000017']; // pri tychto mon. miestach (v atribute waterWithdrawal.waterType.code, v zalozke 'Hydrologické charakteristiky') zobrazim len tie ciselnikove polozky, ktorych atribut type_category (Kategoria typu vody (S - zdroj, W - odpadova, T - odoberana, G - odbery PZV)) je rovný hodnote 'G'

export const showInternationLocationCode = ['27000011']; // pri tychto typoch mon. miest sa zobrazia (v stlpci detail - positionType) namiesto nazvu ciselnika (ciselnik c. 33 - POSITION) jeho atribut ("Medzinárodný kód umiestnenia") vo vyhladavacej tabulke mon. miest + vo vyhľadavacej obrazovke v dostupných a vybratých miestach sa zobrazi namiesto atribútu "placeName" atribut "Medzinárodný kód umiestnenia " (z ciselnika c. 33 - POSITION)

export const doNotGetHydrograficalCodeFromMap = ['27000005', '27000007', '27000008', '27000021', '27000022']; // pri týchto typoch mon. miesta sa z mapy nepreberá hygrografické číslo, aj napriek tomu, že daný atribút sa má na danom type mon. miesta zobrazovať

export const getQuarterFromMap = [
    '27000002',
    '27000005',
    '27000006',
    '27000008',
    '27000016',
    '27000017',
    '27000020',
    '27000021',
    '27000023'
]; // pri týchto typoch mon. miest chceme získať z mapy kvartér

export const getForequarterFromMap = ['27000005', '27000007', '27000016', '27000018', '27000020', '27000021', '27000022', '27000023']; // pri týchto typoch mon. miest chceme získať z mapy kvartér

export const hasEitherQuarterOrForequarter = ['27000020']; // pre tento typ mon. miesta plati, že ak pride z mapy kvarter, tak vyplnim ten, ak nepride kvarter, tak vypnim predkvarter

export const hasEitherQuarterOrForequarterAccordingCode = ['27000005', '27000021']; // pre tento typ mon. miesta plati, že ak kod miesta je mezi 4XXX a 5XXX tak z mapy treba prebrat predkvartér, ak je to menej ako 4XXX a viac ako 6XXX tak kvartér

// createCopy - V pripade, ze sa zmenia monitorovacie miesta, ktore sa budu moct kopirovat, pripadne sa zmenia ich atributy, treba si zistit v src -> components -> monitoringPoint -> attributes -> jednotlivych suboroch, ktore atributy sa mozu skopirovat a ktore musia byt vynulovane  pri nacitani defaultnych hodnot v resulte v MoPointPage, aby nezostal na novom mon. mieste nejaky skopirovany atribut, ktory sa nebude dat zmenit (vid ako je to riesene pri undergroungWater - zistujem, ci sa na danom novom mon. mieste ma zobrazit a ak sa ma, tak tam doplnim udaj, z povodneho mon. miesta, ak sa nema zobrazovat tak ho vynulujem.)
export const createCopy = [27000005, 27000007, 27000021, 27000022]; // Pri tychto mon. miestach pri zakladani noveho mon. miesta je moznost skopirovat udaje z existujuceho monitorovacieho miesta okrem udaja o type mon. miesta ten sa zmeni. Viem vytvorit kopiu '27000007' tak, ze mi predvyplni udaje z daneho mon. miesta do nového mon. miesta typu '27000022' a naopak. To iste viem spravit pre mon. miesta '27000005' - '27000021'.

export const editingMonPointRoles = [
    ROLES.ADMINISTRATOR,
    ROLES.MON_POINT_ALL_WRITE,
    ROLES.MON_POINT_QUALITY_PV_WRITE,
    ROLES.MON_POINT_QUANTITY_PV_WRITE,
    ROLES.MON_POINT_QUANTITY_VHB_PV_WRITE,
    ROLES.MON_POINT_ABSTRACTION_PV_WRITE,
    ROLES.MON_POINT_DISCHARGE_PV_WRITE,
    ROLES.MON_POINT_TNMN_PV_WRITE,
    ROLES.MON_POINT_HYMO_PV_WRITE,
    ROLES.MON_POINT_QUALITY_PZV_WRITE,
    ROLES.MON_POINT_QUANTITY_PZV_WRITE,
    ROLES.MON_POINT_QUANTITY_VHB_PZV_WRITE,
    ROLES.MON_POINT_ABSTRACTON_PZV_WRITE,
    ROLES.MON_POINT_DISCHARGE_PZV_WRITE,
    ROLES.MON_POINT_QUALITY_TESTIMONIALS_WRITE,
    ROLES.MON_POINT_QUANTITY_TESTIMONIALS_WRITE
]; // pouzivatelia s touto rolou možu editovať jednotlive zalozky v monitorovacich miestach

export const allMonPointRoles = [
    ROLES.ADMINISTRATOR,
    ROLES.MON_POINT_ALL_READ,
    ROLES.MON_POINT_ALL_WRITE,
    ROLES.MON_POINT_QUALITY_PV_READ,
    ROLES.MON_POINT_QUALITY_PV_WRITE,
    ROLES.MON_POINT_QUANTITY_PV_READ,
    ROLES.MON_POINT_QUANTITY_PV_WRITE,
    ROLES.MON_POINT_QUANTITY_VHB_PV_READ,
    ROLES.MON_POINT_QUANTITY_VHB_PV_WRITE,
    ROLES.MON_POINT_ABSTRACTION_PV_READ,
    ROLES.MON_POINT_ABSTRACTION_PV_WRITE,
    ROLES.MON_POINT_DISCHARGE_PV_READ,
    ROLES.MON_POINT_DISCHARGE_PV_WRITE,
    ROLES.MON_POINT_TNMN_PV_READ,
    ROLES.MON_POINT_TNMN_PV_WRITE,
    ROLES.MON_POINT_HYMO_PV_READ,
    ROLES.MON_POINT_HYMO_PV_WRITE,
    ROLES.MON_POINT_QUALITY_PZV_READ,
    ROLES.MON_POINT_QUALITY_PZV_WRITE,
    ROLES.MON_POINT_QUANTITY_PZV_READ,
    ROLES.MON_POINT_QUANTITY_PZV_WRITE,
    ROLES.MON_POINT_QUANTITY_VHB_PZV_READ,
    ROLES.MON_POINT_QUANTITY_VHB_PZV_WRITE,
    ROLES.MON_POINT_ABSTRACTON_PZV_READ,
    ROLES.MON_POINT_ABSTRACTON_PZV_WRITE,
    ROLES.MON_POINT_DISCHARGE_PZV_READ,
    ROLES.MON_POINT_DISCHARGE_PZV_WRITE,
    ROLES.MON_POINT_QUALITY_TESTIMONIALS_READ,
    ROLES.MON_POINT_QUALITY_TESTIMONIALS_WRITE,
    ROLES.MON_POINT_QUANTITY_TESTIMONIALS_READ,
    ROLES.MON_POINT_QUANTITY_TESTIMONIALS_WRITE
]; // všetky role na monitorovacích miestach

export const mapLayerId: Record<string, number> = {
    // prevzate z public -> map -> monpoint-config.js
    // tieto MM nemaju layerId: '27000011' - TNMN, '27000023' - VIRTUÁLNA STANICA, '27000024' - MM_so_vsetkymi_atributmi
    '27000001': 3, // MONITOROVACIE MIESTO PV kvalita
    '27000002': 6, // ODBER PV
    '27000003': 19, // VYPÚŠŤANIE do PV
    '27000005': 14, // SONDA kvantita
    '27000006': 5, // ODBER PZV
    '27000007': 10, // PRAMEŇ kvantita
    '27000008': 17, // VODOMERNÁ STANICA
    '27000009': 1, // BILANČNÝ PROFIL (KVANTITA PV)
    '27000012': 16, // VODNÁ NÁDRŽ
    '27000013': 11, // PREVOD VODY
    '27000014': 7, // POSUDKY kvalita
    '27000015': 8, // POSUDKY kvantita
    '27000016': 15, // SUMÁR ODBEROV PZV pre VHB
    '27000017': 4, // NOVÉ ZDROJE PZV
    '27000018': 2, // VHB LOKALITA (KVANTITA PzV)
    '27000019': 12, // PRIESKUMNÉ MONITOROVACIE MIESTO
    '27000020': 18, // VYPÚŠŤANIE do PZV
    '27000021': 13, // SONDA kvalita
    '27000022': 9 // PRAMEŇ kvalita
};
