/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdditionalCalculationResponse } from './AdditionalCalculationResponse';
import {
    AdditionalCalculationResponseFromJSON,
    AdditionalCalculationResponseFromJSONTyped,
    AdditionalCalculationResponseToJSON,
} from './AdditionalCalculationResponse';
import type { DataRegistryProductMetadataResponse } from './DataRegistryProductMetadataResponse';
import {
    DataRegistryProductMetadataResponseFromJSON,
    DataRegistryProductMetadataResponseFromJSONTyped,
    DataRegistryProductMetadataResponseToJSON,
} from './DataRegistryProductMetadataResponse';
import type { ImportFileControlResponse } from './ImportFileControlResponse';
import {
    ImportFileControlResponseFromJSON,
    ImportFileControlResponseFromJSONTyped,
    ImportFileControlResponseToJSON,
} from './ImportFileControlResponse';
import type { ImportFilePartMetadataResponse } from './ImportFilePartMetadataResponse';
import {
    ImportFilePartMetadataResponseFromJSON,
    ImportFilePartMetadataResponseFromJSONTyped,
    ImportFilePartMetadataResponseToJSON,
} from './ImportFilePartMetadataResponse';

/**
 * 
 * @export
 * @interface ImportFileMetadataResponse
 */
export interface ImportFileMetadataResponse {
    /**
     * 
     * @type {string}
     * @memberof ImportFileMetadataResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFileMetadataResponse
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportFileMetadataResponse
     */
    sortNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportFileMetadataResponse
     */
    subdomainCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFileMetadataResponse
     */
    fileType?: ImportFileMetadataResponseFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ImportFileMetadataResponse
     */
    separator?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFileMetadataResponse
     */
    encoding?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFileMetadataResponse
     */
    dateFormat?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFileMetadataResponse
     */
    laboratoryCode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportFileMetadataResponse
     */
    fileSuffixes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ImportFileMetadataResponse
     */
    fileNameFormat?: string;
    /**
     * 
     * @type {Array<ImportFilePartMetadataResponse>}
     * @memberof ImportFileMetadataResponse
     */
    fileParts?: Array<ImportFilePartMetadataResponse>;
    /**
     * 
     * @type {Array<ImportFileControlResponse>}
     * @memberof ImportFileMetadataResponse
     */
    fileControls?: Array<ImportFileControlResponse>;
    /**
     * 
     * @type {Array<DataRegistryProductMetadataResponse>}
     * @memberof ImportFileMetadataResponse
     */
    dataRegistryProducts?: Array<DataRegistryProductMetadataResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof ImportFileMetadataResponse
     */
    dataCountByHeader?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImportFileMetadataResponse
     */
    unnest?: boolean;
    /**
     * 
     * @type {AdditionalCalculationResponse}
     * @memberof ImportFileMetadataResponse
     */
    additionalCalculation?: AdditionalCalculationResponse;
}


/**
 * @export
 */
export const ImportFileMetadataResponseFileTypeEnum = {
    Txt: 'TXT',
    Csv: 'CSV',
    Xml: 'XML',
    Xls: 'XLS',
    Xlsx: 'XLSX'
} as const;
export type ImportFileMetadataResponseFileTypeEnum = typeof ImportFileMetadataResponseFileTypeEnum[keyof typeof ImportFileMetadataResponseFileTypeEnum];


/**
 * Check if a given object implements the ImportFileMetadataResponse interface.
 */
export function instanceOfImportFileMetadataResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImportFileMetadataResponseFromJSON(json: any): ImportFileMetadataResponse {
    return ImportFileMetadataResponseFromJSONTyped(json, false);
}

export function ImportFileMetadataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportFileMetadataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'sortNumber': !exists(json, 'sortNumber') ? undefined : json['sortNumber'],
        'subdomainCode': !exists(json, 'subdomainCode') ? undefined : json['subdomainCode'],
        'fileType': !exists(json, 'fileType') ? undefined : json['fileType'],
        'separator': !exists(json, 'separator') ? undefined : json['separator'],
        'encoding': !exists(json, 'encoding') ? undefined : json['encoding'],
        'dateFormat': !exists(json, 'dateFormat') ? undefined : json['dateFormat'],
        'laboratoryCode': !exists(json, 'laboratoryCode') ? undefined : json['laboratoryCode'],
        'fileSuffixes': !exists(json, 'fileSuffixes') ? undefined : json['fileSuffixes'],
        'fileNameFormat': !exists(json, 'fileNameFormat') ? undefined : json['fileNameFormat'],
        'fileParts': !exists(json, 'fileParts') ? undefined : ((json['fileParts'] as Array<any>).map(ImportFilePartMetadataResponseFromJSON)),
        'fileControls': !exists(json, 'fileControls') ? undefined : ((json['fileControls'] as Array<any>).map(ImportFileControlResponseFromJSON)),
        'dataRegistryProducts': !exists(json, 'dataRegistryProducts') ? undefined : ((json['dataRegistryProducts'] as Array<any>).map(DataRegistryProductMetadataResponseFromJSON)),
        'dataCountByHeader': !exists(json, 'dataCountByHeader') ? undefined : json['dataCountByHeader'],
        'unnest': !exists(json, 'unnest') ? undefined : json['unnest'],
        'additionalCalculation': !exists(json, 'additionalCalculation') ? undefined : AdditionalCalculationResponseFromJSON(json['additionalCalculation']),
    };
}

export function ImportFileMetadataResponseToJSON(value?: ImportFileMetadataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'sortNumber': value.sortNumber,
        'subdomainCode': value.subdomainCode,
        'fileType': value.fileType,
        'separator': value.separator,
        'encoding': value.encoding,
        'dateFormat': value.dateFormat,
        'laboratoryCode': value.laboratoryCode,
        'fileSuffixes': value.fileSuffixes,
        'fileNameFormat': value.fileNameFormat,
        'fileParts': value.fileParts === undefined ? undefined : ((value.fileParts as Array<any>).map(ImportFilePartMetadataResponseToJSON)),
        'fileControls': value.fileControls === undefined ? undefined : ((value.fileControls as Array<any>).map(ImportFileControlResponseToJSON)),
        'dataRegistryProducts': value.dataRegistryProducts === undefined ? undefined : ((value.dataRegistryProducts as Array<any>).map(DataRegistryProductMetadataResponseToJSON)),
        'dataCountByHeader': value.dataCountByHeader,
        'unnest': value.unnest,
        'additionalCalculation': AdditionalCalculationResponseToJSON(value.additionalCalculation),
    };
}

