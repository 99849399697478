/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdditionalCalculationOneInMainRowSearchAttributeResponse } from './AdditionalCalculationOneInMainRowSearchAttributeResponse';
import {
    AdditionalCalculationOneInMainRowSearchAttributeResponseFromJSON,
    AdditionalCalculationOneInMainRowSearchAttributeResponseFromJSONTyped,
    AdditionalCalculationOneInMainRowSearchAttributeResponseToJSON,
} from './AdditionalCalculationOneInMainRowSearchAttributeResponse';

/**
 * 
 * @export
 * @interface AdditionalCalculationResponse
 */
export interface AdditionalCalculationResponse {
    /**
     * 
     * @type {string}
     * @memberof AdditionalCalculationResponse
     */
    referenceAttributeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCalculationResponse
     */
    referenceValueCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCalculationResponse
     */
    referencePrimaryValueCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCalculationResponse
     */
    referenceUnderDetLimitCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCalculationResponse
     */
    occurrenceReferenceAttributeType?: AdditionalCalculationResponseOccurrenceReferenceAttributeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCalculationResponse
     */
    predefinedDefaultValueAttributeCode?: string;
    /**
     * 
     * @type {AdditionalCalculationOneInMainRowSearchAttributeResponse}
     * @memberof AdditionalCalculationResponse
     */
    additionalCalculationOneInMainRowSearchAttribute?: AdditionalCalculationOneInMainRowSearchAttributeResponse;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdditionalCalculationResponse
     */
    requiredAdditionalCalculationCodes?: Array<string>;
}


/**
 * @export
 */
export const AdditionalCalculationResponseOccurrenceReferenceAttributeTypeEnum = {
    OneInMainRow: 'ONE_IN_MAIN_ROW',
    OneInEachDependentRow: 'ONE_IN_EACH_DEPENDENT_ROW',
    MoreInMainRow: 'MORE_IN_MAIN_ROW'
} as const;
export type AdditionalCalculationResponseOccurrenceReferenceAttributeTypeEnum = typeof AdditionalCalculationResponseOccurrenceReferenceAttributeTypeEnum[keyof typeof AdditionalCalculationResponseOccurrenceReferenceAttributeTypeEnum];


/**
 * Check if a given object implements the AdditionalCalculationResponse interface.
 */
export function instanceOfAdditionalCalculationResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdditionalCalculationResponseFromJSON(json: any): AdditionalCalculationResponse {
    return AdditionalCalculationResponseFromJSONTyped(json, false);
}

export function AdditionalCalculationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalCalculationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referenceAttributeCode': !exists(json, 'referenceAttributeCode') ? undefined : json['referenceAttributeCode'],
        'referenceValueCode': !exists(json, 'referenceValueCode') ? undefined : json['referenceValueCode'],
        'referencePrimaryValueCode': !exists(json, 'referencePrimaryValueCode') ? undefined : json['referencePrimaryValueCode'],
        'referenceUnderDetLimitCode': !exists(json, 'referenceUnderDetLimitCode') ? undefined : json['referenceUnderDetLimitCode'],
        'occurrenceReferenceAttributeType': !exists(json, 'occurrenceReferenceAttributeType') ? undefined : json['occurrenceReferenceAttributeType'],
        'predefinedDefaultValueAttributeCode': !exists(json, 'predefinedDefaultValueAttributeCode') ? undefined : json['predefinedDefaultValueAttributeCode'],
        'additionalCalculationOneInMainRowSearchAttribute': !exists(json, 'additionalCalculationOneInMainRowSearchAttribute') ? undefined : AdditionalCalculationOneInMainRowSearchAttributeResponseFromJSON(json['additionalCalculationOneInMainRowSearchAttribute']),
        'requiredAdditionalCalculationCodes': !exists(json, 'requiredAdditionalCalculationCodes') ? undefined : json['requiredAdditionalCalculationCodes'],
    };
}

export function AdditionalCalculationResponseToJSON(value?: AdditionalCalculationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referenceAttributeCode': value.referenceAttributeCode,
        'referenceValueCode': value.referenceValueCode,
        'referencePrimaryValueCode': value.referencePrimaryValueCode,
        'referenceUnderDetLimitCode': value.referenceUnderDetLimitCode,
        'occurrenceReferenceAttributeType': value.occurrenceReferenceAttributeType,
        'predefinedDefaultValueAttributeCode': value.predefinedDefaultValueAttributeCode,
        'additionalCalculationOneInMainRowSearchAttribute': AdditionalCalculationOneInMainRowSearchAttributeResponseToJSON(value.additionalCalculationOneInMainRowSearchAttribute),
        'requiredAdditionalCalculationCodes': value.requiredAdditionalCalculationCodes,
    };
}

