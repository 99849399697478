/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.30.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanResponse,
  EndpointExceptionBody,
  OperatorCriteria,
  OperatorDuplicity,
  OperatorInput,
  OperatorResult,
  OperatorUpdate,
  PageOperatorResult,
} from '../models/index';
import {
    BooleanResponseFromJSON,
    BooleanResponseToJSON,
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    OperatorCriteriaFromJSON,
    OperatorCriteriaToJSON,
    OperatorDuplicityFromJSON,
    OperatorDuplicityToJSON,
    OperatorInputFromJSON,
    OperatorInputToJSON,
    OperatorResultFromJSON,
    OperatorResultToJSON,
    OperatorUpdateFromJSON,
    OperatorUpdateToJSON,
    PageOperatorResultFromJSON,
    PageOperatorResultToJSON,
} from '../models/index';

export interface OperatorApiCreateOperatorRequest {
    operatorInput: OperatorInput;
}

export interface OperatorApiDeleteOperatorRequest {
    id: number;
}

export interface OperatorApiGetOperatorByHistoryGroupIdRequest {
    historyGroupId: number;
}

export interface OperatorApiGetOperatorByIdRequest {
    id: number;
}

export interface OperatorApiOperatorByCriteriaRequest {
    operatorCriteria: OperatorCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface OperatorApiOperatorExistsByCodeRequest {
    code: string;
}

export interface OperatorApiOperatorExistsByIcoRequest {
    operatorDuplicity: OperatorDuplicity;
}

export interface OperatorApiRevertOperatorRequest {
    id: number;
}

export interface OperatorApiUpdateOperatorRequest {
    id: number;
    operatorUpdate: OperatorUpdate;
}

export interface OperatorApiUpdateOperatorStatusRequest {
    id: number;
    status: UpdateOperatorStatusStatusEnum;
}

/**
 * 
 */
export class OperatorApi extends runtime.BaseAPI {

    /**
     */
    async createOperatorRaw(requestParameters: OperatorApiCreateOperatorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperatorResult>> {
        if (requestParameters.operatorInput === null || requestParameters.operatorInput === undefined) {
            throw new runtime.RequiredError('operatorInput','Required parameter requestParameters.operatorInput was null or undefined when calling createOperator.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/operator/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OperatorInputToJSON(requestParameters.operatorInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperatorResultFromJSON(jsonValue));
    }

    /**
     */
    async createOperator(operatorInput: OperatorInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperatorResult> {
        const response = await this.createOperatorRaw({ operatorInput: operatorInput }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOperatorRaw(requestParameters: OperatorApiDeleteOperatorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOperator.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/operator/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteOperator(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteOperatorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOperatorByHistoryGroupIdRaw(requestParameters: OperatorApiGetOperatorByHistoryGroupIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperatorResult>> {
        if (requestParameters.historyGroupId === null || requestParameters.historyGroupId === undefined) {
            throw new runtime.RequiredError('historyGroupId','Required parameter requestParameters.historyGroupId was null or undefined when calling getOperatorByHistoryGroupId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/operator/by-history-group-id/{historyGroupId}`.replace(`{${"historyGroupId"}}`, encodeURIComponent(String(requestParameters.historyGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperatorResultFromJSON(jsonValue));
    }

    /**
     */
    async getOperatorByHistoryGroupId(historyGroupId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperatorResult> {
        const response = await this.getOperatorByHistoryGroupIdRaw({ historyGroupId: historyGroupId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOperatorByIdRaw(requestParameters: OperatorApiGetOperatorByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperatorResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOperatorById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/operator/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperatorResultFromJSON(jsonValue));
    }

    /**
     */
    async getOperatorById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperatorResult> {
        const response = await this.getOperatorByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async operatorByCriteriaRaw(requestParameters: OperatorApiOperatorByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageOperatorResult>> {
        if (requestParameters.operatorCriteria === null || requestParameters.operatorCriteria === undefined) {
            throw new runtime.RequiredError('operatorCriteria','Required parameter requestParameters.operatorCriteria was null or undefined when calling operatorByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/operator/operator-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OperatorCriteriaToJSON(requestParameters.operatorCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageOperatorResultFromJSON(jsonValue));
    }

    /**
     */
    async operatorByCriteria(operatorCriteria: OperatorCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageOperatorResult> {
        const response = await this.operatorByCriteriaRaw({ operatorCriteria: operatorCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async operatorExistsByCodeRaw(requestParameters: OperatorApiOperatorExistsByCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling operatorExistsByCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/operator/code-exists/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     */
    async operatorExistsByCode(code: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanResponse> {
        const response = await this.operatorExistsByCodeRaw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     */
    async operatorExistsByIcoRaw(requestParameters: OperatorApiOperatorExistsByIcoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.operatorDuplicity === null || requestParameters.operatorDuplicity === undefined) {
            throw new runtime.RequiredError('operatorDuplicity','Required parameter requestParameters.operatorDuplicity was null or undefined when calling operatorExistsByIco.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/operator/ico-exists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OperatorDuplicityToJSON(requestParameters.operatorDuplicity),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     */
    async operatorExistsByIco(operatorDuplicity: OperatorDuplicity, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanResponse> {
        const response = await this.operatorExistsByIcoRaw({ operatorDuplicity: operatorDuplicity }, initOverrides);
        return await response.value();
    }

    /**
     */
    async revertOperatorRaw(requestParameters: OperatorApiRevertOperatorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperatorResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling revertOperator.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/operator/revert/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperatorResultFromJSON(jsonValue));
    }

    /**
     */
    async revertOperator(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperatorResult> {
        const response = await this.revertOperatorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOperatorRaw(requestParameters: OperatorApiUpdateOperatorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperatorResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOperator.');
        }

        if (requestParameters.operatorUpdate === null || requestParameters.operatorUpdate === undefined) {
            throw new runtime.RequiredError('operatorUpdate','Required parameter requestParameters.operatorUpdate was null or undefined when calling updateOperator.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/operator/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OperatorUpdateToJSON(requestParameters.operatorUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperatorResultFromJSON(jsonValue));
    }

    /**
     */
    async updateOperator(id: number, operatorUpdate: OperatorUpdate, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperatorResult> {
        const response = await this.updateOperatorRaw({ id: id, operatorUpdate: operatorUpdate }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOperatorStatusRaw(requestParameters: OperatorApiUpdateOperatorStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperatorResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOperatorStatus.');
        }

        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling updateOperatorStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/operator/update-status/{id}/{status}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"status"}}`, encodeURIComponent(String(requestParameters.status))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperatorResultFromJSON(jsonValue));
    }

    /**
     */
    async updateOperatorStatus(id: number, status: UpdateOperatorStatusStatusEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperatorResult> {
        const response = await this.updateOperatorStatusRaw({ id: id, status: status }, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const UpdateOperatorStatusStatusEnum = {
    Delete: 'DELETE',
    Block: 'BLOCK',
    Ok: 'OK',
    Wait: 'WAIT'
} as const;
export type UpdateOperatorStatusStatusEnum = typeof UpdateOperatorStatusStatusEnum[keyof typeof UpdateOperatorStatusStatusEnum];
