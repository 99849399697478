/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.30.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitoringPointCriteria } from './MonitoringPointCriteria';
import {
    MonitoringPointCriteriaFromJSON,
    MonitoringPointCriteriaFromJSONTyped,
    MonitoringPointCriteriaToJSON,
} from './MonitoringPointCriteria';

/**
 * 
 * @export
 * @interface MonitoringPointExportDataSo
 */
export interface MonitoringPointExportDataSo {
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointExportDataSo
     */
    sort: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointExportDataSo
     */
    csvDelimiter: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringPointExportDataSo
     */
    onlyHeader: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitoringPointExportDataSo
     */
    fieldsToExport: Array<string>;
    /**
     * 
     * @type {MonitoringPointCriteria}
     * @memberof MonitoringPointExportDataSo
     */
    criteria: MonitoringPointCriteria;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointExportDataSo
     */
    monitoringPointExportType?: MonitoringPointExportDataSoMonitoringPointExportTypeEnum;
}


/**
 * @export
 */
export const MonitoringPointExportDataSoMonitoringPointExportTypeEnum = {
    UndergroundWater: 'UNDERGROUND_WATER',
    Other: 'OTHER'
} as const;
export type MonitoringPointExportDataSoMonitoringPointExportTypeEnum = typeof MonitoringPointExportDataSoMonitoringPointExportTypeEnum[keyof typeof MonitoringPointExportDataSoMonitoringPointExportTypeEnum];


/**
 * Check if a given object implements the MonitoringPointExportDataSo interface.
 */
export function instanceOfMonitoringPointExportDataSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sort" in value;
    isInstance = isInstance && "csvDelimiter" in value;
    isInstance = isInstance && "onlyHeader" in value;
    isInstance = isInstance && "fieldsToExport" in value;
    isInstance = isInstance && "criteria" in value;

    return isInstance;
}

export function MonitoringPointExportDataSoFromJSON(json: any): MonitoringPointExportDataSo {
    return MonitoringPointExportDataSoFromJSONTyped(json, false);
}

export function MonitoringPointExportDataSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringPointExportDataSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sort': json['sort'],
        'csvDelimiter': json['csvDelimiter'],
        'onlyHeader': json['onlyHeader'],
        'fieldsToExport': json['fieldsToExport'],
        'criteria': MonitoringPointCriteriaFromJSON(json['criteria']),
        'monitoringPointExportType': !exists(json, 'monitoringPointExportType') ? undefined : json['monitoringPointExportType'],
    };
}

export function MonitoringPointExportDataSoToJSON(value?: MonitoringPointExportDataSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sort': value.sort,
        'csvDelimiter': value.csvDelimiter,
        'onlyHeader': value.onlyHeader,
        'fieldsToExport': value.fieldsToExport,
        'criteria': MonitoringPointCriteriaToJSON(value.criteria),
        'monitoringPointExportType': value.monitoringPointExportType,
    };
}

