/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EndpointExceptionBody,
  ErrorPageRequestSo,
  ExportErrorSo,
  PageErrorPageResponseSo,
} from '../models/index';
import {
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    ErrorPageRequestSoFromJSON,
    ErrorPageRequestSoToJSON,
    ExportErrorSoFromJSON,
    ExportErrorSoToJSON,
    PageErrorPageResponseSoFromJSON,
    PageErrorPageResponseSoToJSON,
} from '../models/index';

export interface ErrorApiExportErrorCsvRequest {
    exportErrorSo: ExportErrorSo;
}

export interface ErrorApiExportErrorXlsxRequest {
    exportErrorSo: ExportErrorSo;
}

export interface ErrorApiGetErrorsRequest {
    errorPageRequestSo: ErrorPageRequestSo;
}

/**
 * 
 */
export class ErrorApi extends runtime.BaseAPI {

    /**
     * Export error by preview reference with filter in CSV format
     */
    async exportErrorCsvRaw(requestParameters: ErrorApiExportErrorCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.exportErrorSo === null || requestParameters.exportErrorSo === undefined) {
            throw new runtime.RequiredError('exportErrorSo','Required parameter requestParameters.exportErrorSo was null or undefined when calling exportErrorCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/error/export/csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportErrorSoToJSON(requestParameters.exportErrorSo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Export error by preview reference with filter in CSV format
     */
    async exportErrorCsv(exportErrorSo: ExportErrorSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.exportErrorCsvRaw({ exportErrorSo: exportErrorSo }, initOverrides);
        return await response.value();
    }

    /**
     * Export error by preview reference with filter in Xlsx format
     */
    async exportErrorXlsxRaw(requestParameters: ErrorApiExportErrorXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.exportErrorSo === null || requestParameters.exportErrorSo === undefined) {
            throw new runtime.RequiredError('exportErrorSo','Required parameter requestParameters.exportErrorSo was null or undefined when calling exportErrorXlsx.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/error/export/xlsx`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportErrorSoToJSON(requestParameters.exportErrorSo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Export error by preview reference with filter in Xlsx format
     */
    async exportErrorXlsx(exportErrorSo: ExportErrorSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.exportErrorXlsxRaw({ exportErrorSo: exportErrorSo }, initOverrides);
        return await response.value();
    }

    /**
     * Gets errors for given reference
     */
    async getErrorsRaw(requestParameters: ErrorApiGetErrorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageErrorPageResponseSo>> {
        if (requestParameters.errorPageRequestSo === null || requestParameters.errorPageRequestSo === undefined) {
            throw new runtime.RequiredError('errorPageRequestSo','Required parameter requestParameters.errorPageRequestSo was null or undefined when calling getErrors.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/error`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ErrorPageRequestSoToJSON(requestParameters.errorPageRequestSo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageErrorPageResponseSoFromJSON(jsonValue));
    }

    /**
     * Gets errors for given reference
     */
    async getErrors(errorPageRequestSo: ErrorPageRequestSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageErrorPageResponseSo> {
        const response = await this.getErrorsRaw({ errorPageRequestSo: errorPageRequestSo }, initOverrides);
        return await response.value();
    }

}
