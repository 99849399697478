/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentCriteriaRequest
 */
export interface DocumentCriteriaRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentCriteriaRequest
     */
    searchField?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentCriteriaRequest
     */
    importStatus?: DocumentCriteriaRequestImportStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DocumentCriteriaRequest
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentCriteriaRequest
     */
    fileType?: DocumentCriteriaRequestFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DocumentCriteriaRequest
     */
    created?: string;
}


/**
 * @export
 */
export const DocumentCriteriaRequestImportStatusEnum = {
    Changed: 'CHANGED',
    Deleted: 'DELETED',
    Validated: 'VALIDATED',
    StartedProcessPrimaryAttribute: 'STARTED_PROCESS_PRIMARY_ATTRIBUTE',
    CompletedProcessPrimaryAttribute: 'COMPLETED_PROCESS_PRIMARY_ATTRIBUTE',
    FailedProcessPrimaryAttribute: 'FAILED_PROCESS_PRIMARY_ATTRIBUTE',
    StartedProcessAuxiliaryAttribute: 'STARTED_PROCESS_AUXILIARY_ATTRIBUTE',
    CompletedProcessAuxiliaryAttribute: 'COMPLETED_PROCESS_AUXILIARY_ATTRIBUTE',
    FailedProcessAuxiliaryAttribute: 'FAILED_PROCESS_AUXILIARY_ATTRIBUTE',
    StartedProcessCalculationAdditionalAttribute: 'STARTED_PROCESS_CALCULATION_ADDITIONAL_ATTRIBUTE',
    CompletedProcessCalculationAdditionalAttribute: 'COMPLETED_PROCESS_CALCULATION_ADDITIONAL_ATTRIBUTE',
    FailedProcessCalculationAdditionalAttribute: 'FAILED_PROCESS_CALCULATION_ADDITIONAL_ATTRIBUTE',
    StartedCollectTransformationError: 'STARTED_COLLECT_TRANSFORMATION_ERROR',
    CompletedCollectTransformationError: 'COMPLETED_COLLECT_TRANSFORMATION_ERROR',
    FailedCollectTransformationError: 'FAILED_COLLECT_TRANSFORMATION_ERROR',
    StartedConvertToDataRegistry: 'STARTED_CONVERT_TO_DATA_REGISTRY',
    CompletedConvertToDataRegistry: 'COMPLETED_CONVERT_TO_DATA_REGISTRY',
    FailedConvertToDataRegistry: 'FAILED_CONVERT_TO_DATA_REGISTRY',
    StartedSendingToDataRegistry: 'STARTED_SENDING_TO_DATA_REGISTRY',
    CompletedSendingToDataRegistry: 'COMPLETED_SENDING_TO_DATA_REGISTRY',
    FailedSendingToDataRegistry: 'FAILED_SENDING_TO_DATA_REGISTRY',
    IncompletedSendingToDataRegistry: 'INCOMPLETED_SENDING_TO_DATA_REGISTRY',
    ProcessingInDataRegistryUnknownStatus: 'PROCESSING_IN_DATA_REGISTRY_UNKNOWN_STATUS',
    CompletedProcessingInDataRegistry: 'COMPLETED_PROCESSING_IN_DATA_REGISTRY',
    FailedProcessingInDataRegistry: 'FAILED_PROCESSING_IN_DATA_REGISTRY',
    ScheduledOrRunningProcessingInDataRegistry: 'SCHEDULED_OR_RUNNING_PROCESSING_IN_DATA_REGISTRY',
    UploadQueued: 'UPLOAD_QUEUED',
    StartedUploadingImportFile: 'STARTED_UPLOADING_IMPORT_FILE',
    CompletedUploadingImportFile: 'COMPLETED_UPLOADING_IMPORT_FILE',
    FailedUploadingImportFile: 'FAILED_UPLOADING_IMPORT_FILE',
    StartedDataValidation: 'STARTED_DATA_VALIDATION',
    CompletedDataValidation: 'COMPLETED_DATA_VALIDATION',
    FailedDataValidation: 'FAILED_DATA_VALIDATION',
    StartedManualDataValidation: 'STARTED_MANUAL_DATA_VALIDATION',
    CompletedManualDataValidation: 'COMPLETED_MANUAL_DATA_VALIDATION',
    FailedManualDataValidation: 'FAILED_MANUAL_DATA_VALIDATION'
} as const;
export type DocumentCriteriaRequestImportStatusEnum = typeof DocumentCriteriaRequestImportStatusEnum[keyof typeof DocumentCriteriaRequestImportStatusEnum];

/**
 * @export
 */
export const DocumentCriteriaRequestFileTypeEnum = {
    Txt: 'TXT',
    Csv: 'CSV',
    Xls: 'XLS',
    Xlsx: 'XLSX'
} as const;
export type DocumentCriteriaRequestFileTypeEnum = typeof DocumentCriteriaRequestFileTypeEnum[keyof typeof DocumentCriteriaRequestFileTypeEnum];


/**
 * Check if a given object implements the DocumentCriteriaRequest interface.
 */
export function instanceOfDocumentCriteriaRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DocumentCriteriaRequestFromJSON(json: any): DocumentCriteriaRequest {
    return DocumentCriteriaRequestFromJSONTyped(json, false);
}

export function DocumentCriteriaRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentCriteriaRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'importStatus': !exists(json, 'importStatus') ? undefined : json['importStatus'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'fileType': !exists(json, 'fileType') ? undefined : json['fileType'],
        'created': !exists(json, 'created') ? undefined : json['created'],
    };
}

export function DocumentCriteriaRequestToJSON(value?: DocumentCriteriaRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchField': value.searchField,
        'importStatus': value.importStatus,
        'filename': value.filename,
        'fileType': value.fileType,
        'created': value.created,
    };
}

