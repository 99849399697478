/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.30.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItem } from './CodelistItem';
import {
    CodelistItemFromJSON,
    CodelistItemFromJSONTyped,
    CodelistItemToJSON,
} from './CodelistItem';
import type { EnvironmentResultForMonitoringPoint } from './EnvironmentResultForMonitoringPoint';
import {
    EnvironmentResultForMonitoringPointFromJSON,
    EnvironmentResultForMonitoringPointFromJSONTyped,
    EnvironmentResultForMonitoringPointToJSON,
} from './EnvironmentResultForMonitoringPoint';
import type { WaterShapeSoCodelistItem } from './WaterShapeSoCodelistItem';
import {
    WaterShapeSoCodelistItemFromJSON,
    WaterShapeSoCodelistItemFromJSONTyped,
    WaterShapeSoCodelistItemToJSON,
} from './WaterShapeSoCodelistItem';

/**
 * 
 * @export
 * @interface WaterCourseResultCodelistItem
 */
export interface WaterCourseResultCodelistItem {
    /**
     * 
     * @type {number}
     * @memberof WaterCourseResultCodelistItem
     */
    gisKm?: number;
    /**
     * 
     * @type {string}
     * @memberof WaterCourseResultCodelistItem
     */
    hydrographicNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof WaterCourseResultCodelistItem
     */
    vhmKm?: number;
    /**
     * 
     * @type {CodelistItem}
     * @memberof WaterCourseResultCodelistItem
     */
    assesmentWaterFlowType?: CodelistItem | null;
    /**
     * 
     * @type {EnvironmentResultForMonitoringPoint}
     * @memberof WaterCourseResultCodelistItem
     */
    environment?: EnvironmentResultForMonitoringPoint;
    /**
     * 
     * @type {WaterShapeSoCodelistItem}
     * @memberof WaterCourseResultCodelistItem
     */
    waterShape?: WaterShapeSoCodelistItem;
}

/**
 * Check if a given object implements the WaterCourseResultCodelistItem interface.
 */
export function instanceOfWaterCourseResultCodelistItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterCourseResultCodelistItemFromJSON(json: any): WaterCourseResultCodelistItem {
    return WaterCourseResultCodelistItemFromJSONTyped(json, false);
}

export function WaterCourseResultCodelistItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterCourseResultCodelistItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gisKm': !exists(json, 'gisKm') ? undefined : json['gisKm'],
        'hydrographicNumber': !exists(json, 'hydrographicNumber') ? undefined : json['hydrographicNumber'],
        'vhmKm': !exists(json, 'vhmKm') ? undefined : json['vhmKm'],
        'assesmentWaterFlowType': !exists(json, 'assesmentWaterFlowType') ? undefined : CodelistItemFromJSON(json['assesmentWaterFlowType']),
        'environment': !exists(json, 'environment') ? undefined : EnvironmentResultForMonitoringPointFromJSON(json['environment']),
        'waterShape': !exists(json, 'waterShape') ? undefined : WaterShapeSoCodelistItemFromJSON(json['waterShape']),
    };
}

export function WaterCourseResultCodelistItemToJSON(value?: WaterCourseResultCodelistItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gisKm': value.gisKm,
        'hydrographicNumber': value.hydrographicNumber,
        'vhmKm': value.vhmKm,
        'assesmentWaterFlowType': CodelistItemToJSON(value.assesmentWaterFlowType),
        'environment': EnvironmentResultForMonitoringPointToJSON(value.environment),
        'waterShape': WaterShapeSoCodelistItemToJSON(value.waterShape),
    };
}

