/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.30.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItem } from './CodelistItem';
import {
    CodelistItemFromJSON,
    CodelistItemFromJSONTyped,
    CodelistItemToJSON,
} from './CodelistItem';
import type { InstallationResultForMonitoringPoint } from './InstallationResultForMonitoringPoint';
import {
    InstallationResultForMonitoringPointFromJSON,
    InstallationResultForMonitoringPointFromJSONTyped,
    InstallationResultForMonitoringPointToJSON,
} from './InstallationResultForMonitoringPoint';

/**
 * 
 * @export
 * @interface WaterWithdrawalResultCodelistItem
 */
export interface WaterWithdrawalResultCodelistItem {
    /**
     * 
     * @type {string}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    balanceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    continuityCodeBackward?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    continuityCodeForward?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    dopsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    guaranteedFlow?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    hydrofundCode?: string;
    /**
     * 
     * @type {number}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    orderNumber?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    permit: boolean | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    specificPurposeType?: CodelistItem | null;
    /**
     * 
     * @type {string}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    waterLawDecisionNumber?: string;
    /**
     * 
     * @type {CodelistItem}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    waterType?: CodelistItem | null;
    /**
     * 
     * @type {InstallationResultForMonitoringPoint}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    installation?: InstallationResultForMonitoringPoint;
    /**
     * 
     * @type {CodelistItem}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    naceType?: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    nace2Type?: CodelistItem | null;
    /**
     * 
     * @type {number}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    q1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    q270?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    q355?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterWithdrawalResultCodelistItem
     */
    qa?: number | null;
}

/**
 * Check if a given object implements the WaterWithdrawalResultCodelistItem interface.
 */
export function instanceOfWaterWithdrawalResultCodelistItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "permit" in value;

    return isInstance;
}

export function WaterWithdrawalResultCodelistItemFromJSON(json: any): WaterWithdrawalResultCodelistItem {
    return WaterWithdrawalResultCodelistItemFromJSONTyped(json, false);
}

export function WaterWithdrawalResultCodelistItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterWithdrawalResultCodelistItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'balanceCode': !exists(json, 'balanceCode') ? undefined : json['balanceCode'],
        'continuityCodeBackward': !exists(json, 'continuityCodeBackward') ? undefined : json['continuityCodeBackward'],
        'continuityCodeForward': !exists(json, 'continuityCodeForward') ? undefined : json['continuityCodeForward'],
        'dopsCode': !exists(json, 'dopsCode') ? undefined : json['dopsCode'],
        'guaranteedFlow': !exists(json, 'guaranteedFlow') ? undefined : json['guaranteedFlow'],
        'hydrofundCode': !exists(json, 'hydrofundCode') ? undefined : json['hydrofundCode'],
        'orderNumber': !exists(json, 'orderNumber') ? undefined : json['orderNumber'],
        'permit': json['permit'],
        'specificPurposeType': !exists(json, 'specificPurposeType') ? undefined : CodelistItemFromJSON(json['specificPurposeType']),
        'waterLawDecisionNumber': !exists(json, 'waterLawDecisionNumber') ? undefined : json['waterLawDecisionNumber'],
        'waterType': !exists(json, 'waterType') ? undefined : CodelistItemFromJSON(json['waterType']),
        'installation': !exists(json, 'installation') ? undefined : InstallationResultForMonitoringPointFromJSON(json['installation']),
        'naceType': !exists(json, 'naceType') ? undefined : CodelistItemFromJSON(json['naceType']),
        'nace2Type': !exists(json, 'nace2Type') ? undefined : CodelistItemFromJSON(json['nace2Type']),
        'q1': !exists(json, 'q1') ? undefined : json['q1'],
        'q270': !exists(json, 'q270') ? undefined : json['q270'],
        'q355': !exists(json, 'q355') ? undefined : json['q355'],
        'qa': !exists(json, 'qa') ? undefined : json['qa'],
    };
}

export function WaterWithdrawalResultCodelistItemToJSON(value?: WaterWithdrawalResultCodelistItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'balanceCode': value.balanceCode,
        'continuityCodeBackward': value.continuityCodeBackward,
        'continuityCodeForward': value.continuityCodeForward,
        'dopsCode': value.dopsCode,
        'guaranteedFlow': value.guaranteedFlow,
        'hydrofundCode': value.hydrofundCode,
        'orderNumber': value.orderNumber,
        'permit': value.permit,
        'specificPurposeType': CodelistItemToJSON(value.specificPurposeType),
        'waterLawDecisionNumber': value.waterLawDecisionNumber,
        'waterType': CodelistItemToJSON(value.waterType),
        'installation': InstallationResultForMonitoringPointToJSON(value.installation),
        'naceType': CodelistItemToJSON(value.naceType),
        'nace2Type': CodelistItemToJSON(value.nace2Type),
        'q1': value.q1,
        'q270': value.q270,
        'q355': value.q355,
        'qa': value.qa,
    };
}

